import React, { useState } from 'react'
import Swal from 'sweetalert2'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useLazyQuery } from '@apollo/react-hooks'

import { MyInformation } from '../../../types'
import { useAppDispatch } from '../../../hooks'
import { SimpleKnTField } from '../../../components'
import { VALIDATE_GUEST_BOOKING } from '../../../graphql'
import { setMyInfo } from '../../../features/bookingSlice'
import { loaderAlert, simpleAlert, useCurrentUser } from '../../../common'
import { setMyInfo as setMyInfoPublic } from '../../../features/publicBookingSlice'

export const VerifyMyInfo: React.FC<{
  eventComponentId: string
  verify: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ verify, eventComponentId }) => {
  const dispatch = useAppDispatch()
  const currentUser = useCurrentUser()
  const [secondEmail, setSecondEmail] = useState(false)
  const [validateGuestBooking] = useLazyQuery(VALIDATE_GUEST_BOOKING)

  const saveMyInfo = (info: MyInformation) => {
    // because we have two slices for booking - authenticated and unauthenticated
    if (currentUser.isAuthenticated) {
      dispatch(setMyInfo(info))
    } else {
      dispatch(setMyInfoPublic(info))
    }
    verify(true)
  }

  const handleVerify = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    loaderAlert({ html: 'Validating your information' })

    const firstName = ev.currentTarget.firstName?.value
    const lastName = ev.currentTarget.lastName?.value
    const email = ev.currentTarget.email?.value
    const secondaryEmail = ev.currentTarget?.secondaryEmail?.value

    const variables = {
      eventComponentId,
      firstName,
      lastName,
      email: !secondEmail ? email : secondaryEmail,
    }
    validateGuestBooking({ variables })
      .then(({ data }) => {
        Swal.close()
        const valid = data?.validateGuestBooking
        if (!valid && !secondEmail) {
          setSecondEmail(true)
          return
        }

        if (!valid && secondEmail) {
          simpleAlert({
            html: 'This email is not valid for this event',
            icon: 'error',
          })
          return
        }

        saveMyInfo({
          firstName,
          lastName,
          email,
          secondaryEmail,
        })
      })
      .catch(() => {
        simpleAlert({ html: 'Error validating guest booking', icon: 'error' })
      })
  }

  return (
    <div className="w-100 my-4 py-4 px-4 card">
      <h4 className="text-left mb-0">My Information</h4>
      <span className="fz-12 tc-lgray">
        Enter a valid email address for this event.
      </span>

      <form onSubmit={handleVerify}>
        <Row>
          <Col xs={12} md={6} lg={secondEmail ? 3 : 4}>
            <SimpleKnTField
              label="First Name"
              name="firstName"
              placeholder="Enter your first name"
              className="mt-4"
              defaultValue={currentUser.firstName}
            />
          </Col>
          <Col xs={12} md={6} lg={secondEmail ? 3 : 4}>
            <SimpleKnTField
              label="Last Name"
              name="lastName"
              placeholder="Enter your last name"
              className="mt-4"
              defaultValue={currentUser.lastName}
            />
          </Col>
          <Col xs={12} md={6} lg={secondEmail ? 3 : 4}>
            <SimpleKnTField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              className="mt-4"
              defaultValue={currentUser.email}
            />
          </Col>
          {secondEmail && (
            <Col xs={12} md={6} lg={3}>
              <SimpleKnTField
                label="Secondary Email"
                name="secondaryEmail"
                type="email"
                placeholder="Enter a secondary email"
                className="mt-4"
              />
            </Col>
          )}
        </Row>
        {secondEmail && (
          <Row>
            <Col className="text-left">
              <span className="fz-12 text-danger">
                Oops! We couldn’t find your email on the event guest list. If
                you have an alternate email that might be listed, please enter
                it below. If you’re unsure which email the host used for you or
                your guest, we recommend reaching out to them directly for
                assistance.
              </span>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="text-right">
            <Button type="submit" className="mt-3 px-5">
              Verify
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}
