import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { MdCancel } from 'react-icons/md'

import { SiteLogo } from '../customIcons'
import { salmon } from '../../styles/themeColors'
import { ISignUpInForValidations } from '../../types'

import { Signin } from './Signin'
import { Signup } from './Signup'
import { SignInUpContainer } from './style'

export const SignUpSignInForValidations: React.FC<ISignUpInForValidations> = ({
  open,
  onClose,
  title,
  text,
  type = 'login',
  id,
  preloadedData,
  afterLoginCallback,
  afterRegisterCallback,
}) => {
  const close = () => {
    onClose()
    // function to close the modal in case we need extra logic like the other login modal
  }

  return (
    <Modal centered show={open} onHide={onClose} className="modal-rounded-0">
      <SignInUpContainer className="validations">
        <MdCancel size={20} className="closeModal cursor" onClick={onClose} />
        <Modal.Body className="text-center">
          <SiteLogo width="57px" height="40px" color={salmon} />
          <h4 className="mt-1">{title}</h4>
          {/* eslint-disable-next-line react/no-danger */}
          {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
        </Modal.Body>

        <Tabs defaultActiveKey={type} id={id || 'SigninSignupTabsValidations'}>
          <Tab eventKey="login" title="Login">
            <Signin
              onClose={close}
              preloadedData={preloadedData}
              callback={afterLoginCallback}
            />
            <Link
              to="/reset-password"
              className="w-fit-content d-block fz-14 mt-3 mx-auto"
              onClick={onClose}
            >
              Forgot password?
            </Link>
          </Tab>
          <Tab eventKey="register" title="Register">
            <Signup
              preloadedData={preloadedData}
              onClose={close}
              callback={afterRegisterCallback}
            />
          </Tab>
        </Tabs>
      </SignInUpContainer>
    </Modal>
  )
}
