import React, { useState, useRef } from 'react'
import { MdClose } from 'react-icons/md'

import { darkGray } from '../../styles/themeColors'

import { GridContainer } from './style'
import { GridItemsPerPage, GridProps, GridRow, SortConfig } from './types'
import {
  getIcon,
  handleInputChange,
  generatePageNumbers,
} from './gridFunctions'

export const Grid: React.FC<GridProps> = ({
  className = '',
  itemsPerPage,
  header,
  data,
  onSort,
  onChange,
  showRowNumbers = false,
  search,
  hideItemsPerPage,
}) => {
  const [itemsPerPageOption, setItemsPerPageOption] = useState(() => {
    if (itemsPerPage === 'all') return data.length // all rows
    if (itemsPerPage) return itemsPerPage // custom value from props

    // in case the itemsPerPage is not provided
    if (data.length > 100) return 500
    if (data.length > 25) return 100
    return 25
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [sortConfig, setSortConfig] = useState<SortConfig>()
  const inputRef = useRef<HTMLInputElement>(null)
  const totalItems = data.length
  const lastIndex = currentPage * itemsPerPageOption
  const firstIndex = lastIndex - itemsPerPageOption

  const renderRow = (item: GridRow, idx: number) => (
    <tr key={item.id} className={(item.className ?? '') as string}>
      {showRowNumbers && <td className="row-number">{firstIndex + idx + 1}</td>}
      {header.map(column => (
        <td
          key={column.key}
          className={`${column.key}-column`}
          style={{ textAlign: column?.align ?? 'unset' }}
        >
          {item[column.key]}
        </td>
      ))}
    </tr>
  )

  const totalPages = Math.ceil(totalItems / itemsPerPageOption)
  const pageNumbers = generatePageNumbers(currentPage, totalPages)
  const rows = data
    ?.slice(firstIndex, lastIndex)
    ?.map((item, idx) => renderRow(item, idx))

  const sort = (key: string) => {
    let newSortConfig: SortConfig | undefined
    if (sortConfig?.key !== key) {
      // order by asc on first click
      newSortConfig = { key, direction: 'asc' }
    } else if (sortConfig?.direction === 'desc') {
      // remove sort on third click
      newSortConfig = undefined
    } else {
      // order by desc on second click
      newSortConfig = { key, direction: 'desc' }
    }
    setSortConfig(newSortConfig)
    onSort?.(newSortConfig)
  }

  const resetPg = () => setCurrentPage(1)

  const handleClearSearch = () => {
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
    onChange?.(undefined)
    resetPg()
  }

  const itemsPerPageSelector = () => {
    if (hideItemsPerPage) return null
    return (
      <div className="items-per-page">
        <label htmlFor="itemsPerPage">Items per page:</label>
        <select
          value={
            itemsPerPageOption === data.length ? 'all' : itemsPerPageOption
          }
          onChange={ev => {
            const value = ev.target.value as GridItemsPerPage
            setItemsPerPageOption(value === 'all' ? data.length : value)
          }}
        >
          <option value={25}>25</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
          <option value="all">All</option>
        </select>
      </div>
    )
  }

  return (
    <GridContainer className={className}>
      <div className="grid-header">
        {search && (
          <div className="search-input shadow-sm">
            <input
              type="text"
              placeholder={
                (typeof search === 'object' && search.placeholder) || 'Search'
              }
              ref={inputRef}
              onChange={ev =>
                handleInputChange(ev.target.value, onChange, resetPg)
              }
            />
            {inputRef?.current?.value && (
              <MdClose
                size={20}
                color="rgba(3, 3, 3, 0.5)"
                title="Clear search"
                onClick={handleClearSearch}
              />
            )}
          </div>
        )}
        {itemsPerPageSelector()}
      </div>
      <div className="table-container">
        <table className="custom-grid">
          <thead>
            <tr>
              {showRowNumbers && <th>{/** # */}</th>}
              {header.map(th => (
                <th
                  style={{
                    minWidth: th?.width ?? 'auto',
                    width: th?.width ?? 'auto',
                    textAlign: th?.headerAlign ?? th?.align ?? 'unset',
                  }}
                  key={`th-${th.key}`}
                  onClick={() => th.sort && sort(th.key)}
                >
                  {th.title}
                  {th.sort && getIcon(th.key, sortConfig)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.length <= 0 ? (
              <tr>
                <td colSpan={header.length} className="text-center">
                  No data available
                </td>
              </tr>
            ) : (
              rows
            )}
          </tbody>
        </table>
      </div>
      <div className={rows.length <= 0 ? 'd-none' : 'grid-footer'}>
        <p className="grid-results">
          Showing
          <span>{firstIndex + 1}</span>to
          <span>{Math.min(lastIndex, totalItems)}</span>of
          <span>{totalItems}</span> guests
        </p>
        {itemsPerPageSelector()}
        <div className={totalPages <= 1 ? 'd-none' : 'grid-pagination'}>
          <button
            type="button"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {pageNumbers.map((pageNumber, idx) => {
            if (pageNumber === -1) {
              return (
                <button
                  disabled
                  type="button"
                  key={`page-${idx.toString()}`}
                  style={{ color: darkGray }}
                >
                  ...
                </button>
              )
            }

            return (
              <button
                key={pageNumber}
                type="button"
                onClick={() => setCurrentPage(pageNumber)}
                disabled={pageNumber === currentPage}
              >
                {pageNumber}
              </button>
            )
          })}
          <button
            type="button"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </GridContainer>
  )
}
