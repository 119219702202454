import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { MdCancel } from 'react-icons/md'

import { Signup } from '../../../components/SigninSignup/Signup'
import { Signin } from '../../../components/SigninSignup/Signin'
import { SignInUpContainer } from '../../../components/SigninSignup/style'

import { Form1st100Groups } from './style'

export const ContactForm: React.FC = () => {
  const [modal, openModal] = useState(false)

  const closeModal = () => openModal(false)

  return (
    <>
      <Modal show={modal} onHide={closeModal}>
        <SignInUpContainer className="pb-5">
          <MdCancel
            size={20}
            className="closeModal cursor"
            onClick={closeModal}
          />
          <Tabs defaultActiveKey="login" id="Signin#1st100Groups">
            <Tab eventKey="login" title="Login">
              <Signin onClose={closeModal} marketing />
            </Tab>
            <Tab title="" />
          </Tabs>
        </SignInUpContainer>
      </Modal>

      <Form1st100Groups className="bg-white shadow-sm px-5 pt-4">
        <h3 className="text-primary text-center">
          Register Now For #1st100Groups
        </h3>
        <Signup marketing />
        <span
          onClick={() => openModal(true)}
          style={{ position: 'relative', top: '-15px' }}
          className="text-primary fz-12 w-100 text-center d-block cursor"
        >
          already have an account? log in!
        </span>
      </Form1st100Groups>
    </>
  )
}
