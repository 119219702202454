import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import Swal from 'sweetalert2'
import ReactGA from 'react-ga4'
import unset from 'lodash/fp/unset'
import getOr from 'lodash/fp/getOr'
import { PhoneInput } from 'react-international-phone'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { MdMailOutline, MdLockOutline, MdAccountCircle } from 'react-icons/md'

import { ADD_USER, CREATE_MARKETING_FORM } from '../../graphql'
import { useForm } from '../../common/alterForms'
import { Error, IgenericObject, ISignInUpPreloadedData } from '../../types'
import {
  loaderAlert,
  simpleAlert,
  simpleAlertWithPromise,
  useValidatePassword,
  usePartnership,
  deletePartnershipData,
  deleteInvitationData,
  getInvitationTokenEmail,
} from '../../common'
import { KnTField } from '../../components'
import { gray } from '../../styles/themeColors'

import { useValidatePhone, useSource } from './components'

interface ISignup {
  marketing?: boolean
  preloadedData?: ISignInUpPreloadedData
  onClose?: () => void
  callback?: () => void
}
export const Signup: React.FC<ISignup> = ({
  marketing = false,
  preloadedData,
  onClose,
  callback,
}) => {
  const { getSource } = useSource()
  const [signupForm, { loading }] = useMutation(ADD_USER)
  const [First100] = useMutation(CREATE_MARKETING_FORM)
  const initModel: IgenericObject = {
    firstName: getOr('', 'firstName', preloadedData),
    lastName: getOr('', 'lastName', preloadedData),
    email: getOr('', 'email', preloadedData),
    password: '',
    phone: '',
  }
  const { model, errors, handleFieldChange, pushError } = useForm(initModel)
  const validate = useValidatePassword(model.password)
  const [phone, setPhone] = useState<string>()
  const [phoneError, setPhoneError] = useState('')
  const { validatePhone } = useValidatePhone()
  const { getPartnershipData } = usePartnership()

  const register = (ev: React.FormEvent) => {
    ev.preventDefault()
    if (!validatePhone({ phone, setPhoneError })) return
    if (loading || validate.length > 0) return

    const reCAPTCHAKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
    grecaptcha.ready(() => {
      grecaptcha
        .execute(reCAPTCHAKey, { action: 'submit' })
        .then((token: string) => {
          loaderAlert({ html: 'Processing your registration...' })

          ReactGA.event({
            category: 'Sign Up',
            label: 'Signup page',
            action: 'User completed and sent the sign up form',
          })

          const source = getSource()
          const variables = {
            input: {
              ...model,
              phone,
              source,
              invitationTokenEmail: getInvitationTokenEmail(),
            },
            token,
            partnership: getPartnershipData({ cta: 'normal', source }),
          }

          signupForm({ variables })
            .then(({ data }) => {
              if (data.createCustomer.errors.length > 0) {
                data.createCustomer.errors.forEach(
                  ({ key, message }: Error) => {
                    pushError(message, key)
                  }
                )
              } else {
                deletePartnershipData()
                deleteInvitationData()
              }
              return data.createCustomer.errors.length
            })
            .then((previousErrors: number) => {
              if (previousErrors > 0) {
                return Promise.reject()
              }
              if (!marketing) {
                return Promise.resolve({ data: null })
              }

              const first100Variables = {
                input: {
                  form_name: 'first 100 signup',
                  form_data: JSON.stringify(unset('password', model)),
                },
              }
              return First100({ variables: first100Variables })
            })
            .then(({ data: First100Data }) => {
              Swal.close() // close 'Processing your registration...'

              if (
                marketing &&
                First100Data.createMarketingForm.errors.length === 0
              ) {
                simpleAlert({
                  html: 'Help is on it’s way! Your #1st100Groups Concierge will be in touch',
                  icon: 'success',
                })
              } else {
                simpleAlertWithPromise({
                  html: `Almost there!  Check your email to verify registration`,
                  icon: 'success',
                }).then(() => {
                  if (onClose) {
                    onClose()
                  }

                  if (callback) {
                    callback()
                  }
                })
              }
            })
            .catch(() => {
              Swal.close()
              simpleAlert({
                html: `Your registration could not be completed, please try again later.`,
                icon: 'warning',
              })
            })
        })
    })
  }

  useEffect(() => {
    if (validate.length <= 0) {
      pushError('', 'password')
      return
    }
    if (model.password && validate.length > 0) {
      pushError(validate[0].message, 'password')
    }

    // if I add pushError to the dependencies, it causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.password, validate])

  /**
   * I add "-marketing" to the checkbox ID because that form is always present in the DOM
   * and there is a conflict with the ID if the registration modal is opened
   */
  return (
    <Form data-cy="signup-form" onSubmit={register} className="pb-2">
      <KnTField
        label="First name"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="firstName"
        placeholder="Enter your first name"
        icon={<MdAccountCircle size={24} color={gray} />}
      />
      <KnTField
        label="Last name"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="lastName"
        placeholder="Enter your last name"
        icon={<MdAccountCircle size={24} color={gray} />}
      />
      <PhoneInput
        placeholder="Your phone number*"
        defaultCountry="us"
        value={phone}
        onChange={ev => setPhone(ev)}
        className="international-phone-container label required"
        inputClassName="international-phone-input"
        countrySelectorStyleProps={{
          buttonClassName: 'international-phone-button',
        }}
      />
      {phoneError && (
        <span className="pl-4 fz-12 text-danger d-block">{phoneError}</span>
      )}
      <KnTField
        label="Email"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="email"
        placeholder="your-email@example.com"
        type="email"
        icon={<MdMailOutline size={24} color={gray} />}
      />
      <KnTField
        label="Password"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="password"
        placeholder="Enter your password"
        type="password"
        icon={<MdLockOutline size={24} color={gray} />}
      />

      <div className="d-flex mt-5">
        <FormCheck
          custom
          required
          type="checkbox"
          id={`signup-terms-and-conditions${marketing ? '-marketing' : ''}`}
          label="Accept"
        />
        <Link to="/terms-of-use" target="_blank" className="px-1">
          Terms of Use
        </Link>
        <span>and</span>
        <Link to="/privacy-policy" target="_blank" className="px-1">
          Privacy Policy
        </Link>
      </div>
      <Button className="w-100 my-4" type="submit">
        Sign up
      </Button>
    </Form>
  )
}
