import React, { useState, Suspense, lazy } from 'react'
import ReactGA from 'react-ga4'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { MdPerson } from 'react-icons/md'

import { SigninSignup } from '../SigninSignup'
import Logo from '../../assets/images/logo.png'
import { myHub } from '../../common/constants'
import { salmon } from '../../styles/themeColors'
import { authModalType } from '../../types'

import { HowItWorks } from './HowItWorks'
import { MobileNavbar } from './MobileNavbar'

const HomeNavbar: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [loginModal, openLoginModal] = useState(false)
  const [howItWorks, setHowItWorks] = useState(false)
  const [modalType, setModalType] = useState<authModalType>('login')
  const [calendlyModal, openCalendlyModal] = useState(false)
  const [shouldLoadModal, setShouldLoadModal] = useState(false)

  const ConversionlyModal = lazy(
    () => import('../SigninSignup/ConversionlyModal')
  )

  const loginLink = (type: authModalType = 'login') => {
    setModalType(type)
    ReactGA.event({
      category: `Sign ${type === 'login' ? 'In' : 'Up'}`,
      label: 'Public Navbar',
      action: `User clicked the ${type} link on the navbar`,
    })
    openLoginModal(true)
  }

  const onClose = () => {
    openLoginModal(false)

    // sessionStorage.setItem('auth-return-path', location.pathname)
    const authReturnPath = sessionStorage.getItem('auth-return-path')

    if (authReturnPath) {
      history.push(authReturnPath)
      sessionStorage.removeItem('auth-return-path')
      return
    }

    if (location.pathname === '/') {
      history.push(myHub)
    }
  }

  const openConversionlyModal = (ev: React.MouseEvent) => {
    ev.preventDefault()
    openCalendlyModal(true)
    setShouldLoadModal(true)
    ReactGA.event({
      category: 'Sign Up',
      label: 'Navbar',
      action: 'User clicked "Start planning" on the navbar',
    })
  }

  return (
    <Navbar bg="white" fixed="top" className="shadow-sm">
      <MobileNavbar openHowItWorks={setHowItWorks} handleLogin={loginLink} />
      <Container
        id="responsive-navbar-nav"
        className="d-none d-lg-flex"
        style={{ maxWidth: '1440px' }}
      >
        <Nav className="fwsb mr-auto">
          <Nav.Link className="tc-gray" onClick={() => setHowItWorks(true)}>
            How We Work
          </Nav.Link>
          <HowItWorks open={howItWorks} handle={setHowItWorks} />
          <Nav.Link to="/marketplace" as={Link} className="tc-gray">
            Wedding Destinations
          </Nav.Link>
          <Nav.Link to="/blog" as={Link} className="tc-gray">
            Inspiration
          </Nav.Link>
          <Nav.Link to="/about-us" as={Link} className="tc-gray">
            About Us
          </Nav.Link>
        </Nav>
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="Kiss and Tell" style={{ maxWidth: '149px' }} />
        </Navbar.Brand>
        <Nav className="fwsb ml-auto pl-5">
          <Nav.Link
            to="/signin"
            as={Link}
            className="py-1 ml-5 mr-3"
            onClick={(ev: React.MouseEvent) => {
              ev.preventDefault()
              loginLink()
            }}
          >
            <MdPerson size={24} color={salmon} />
          </Nav.Link>
          <Button
            className="px-3 py-1 mr-2 login-homepage"
            onClick={openConversionlyModal}
          >
            Start Planning
          </Button>
          <SigninSignup open={loginModal} onClose={onClose} type={modalType} />

          {/** this is to lazy load the modal component only when the button is clicked */}
          {shouldLoadModal && (
            <Suspense fallback={<></>}>
              <ConversionlyModal
                open={calendlyModal}
                onClose={() => openCalendlyModal(false)}
              />
            </Suspense>
          )}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default React.memo(HomeNavbar)
