import React, { useEffect } from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import Modal from 'react-bootstrap/Modal'
import { useLazyQuery } from '@apollo/react-hooks'

import { Booking, Guest } from '../../../../types'
import { SmallLoader } from '../../../../components'
import { GET_BOOKINGS_BY_IDS } from '../../../../graphql'
import { offeringTypes } from '../../../../common/constants'

import { RoomBookingsModal } from './RoomBookingsModal'
import { PackageBookingsModal } from './PackageBookingsModal'

export const BookingsModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  bookings: Booking[]
  currentGuest?: Guest
  filterPackages: string[]
}> = ({ isOpen, onClose, bookings = [], currentGuest, filterPackages }) => {
  const { roomOffering } = offeringTypes
  const [getBookings, { loading, data }] = useLazyQuery(GET_BOOKINGS_BY_IDS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isOpen) return

    getBookings({ variables: { ids: map(el => el.id, bookings) } })
  }, [isOpen, bookings, getBookings])

  const renderContent = (isLoading: boolean, list: Booking[] = []) => {
    if (isLoading) {
      return (
        <div className="text-center py-3">
          <SmallLoader />
        </div>
      )
    }

    const areRooms =
      list[0]?.eventComponent.offering.__typename === roomOffering

    if (!areRooms || list.length === 0) {
      return (
        <PackageBookingsModal
          bookings={list}
          currentGuest={currentGuest}
          filterPackages={filterPackages}
        />
      )
    }

    return <RoomBookingsModal bookings={list} />
  }

  return (
    <Modal
      centered
      show={isOpen}
      onHide={onClose}
      className="modal-w-900 modal-rounded-0 th"
    >
      {renderContent(loading, get('bookings', data))}
    </Modal>
  )
}
