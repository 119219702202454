import React, { useState } from 'react'
import some from 'lodash/fp/some'
import get from 'lodash/fp/get'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import Button from 'react-bootstrap/Button'
import { ImCheckmark2 } from 'react-icons/im'

import { SignUpSignInForValidations } from '../..'
import { useCurrentUser, confirmAlert } from '../../../common'
import { green } from '../../../styles/themeColors'
import { offeringTypes } from '../../../common/constants'
import { useCreateEvent } from '../../../pages/Event/eventFunctions'
import { useAddEventComponent } from '../../../pages/Event/Builder/components/eventBuilderFunctions'
import {
  ICreateEventComponentCTA,
  IValidateCard,
  MyEvent,
  EventComponent,
  PackageOffering,
} from '../../../types'

const validateEvendCard = ({
  input,
  eventsCount,
  eventName,
  offering,
}: IValidateCard): string | undefined => {
  const packageOffering = offering as PackageOffering
  if (!eventName && eventsCount <= 0) return 'Please enter your event name'
  if (!input.date) return 'Please select your event date'

  const capacity = input.capacity || 0
  const minimumGuests = packageOffering.package.minimumGuests || 1
  if (capacity < minimumGuests) {
    return 'Invalid number of guests'
  }
  if (capacity > offering.quantity) {
    return `maximum number of guests exceeded (max: ${offering.quantity})`
  }

  return undefined
}

const validateRoomCard = ({
  input,
  eventsCount,
  eventName,
  offering,
}: IValidateCard): string | undefined => {
  if (!eventName && eventsCount <= 0) return 'Please enter your event name'
  const capacity = input.capacity || 0
  if (capacity < 1) return 'Invalid number of rooms'
  if (capacity > offering.quantity) {
    return `limit exceeded (max: ${offering.quantity})`
  }
  if (!input.date) {
    return 'Enter Check in / Check out dates to proceed with room selection'
  }
  return undefined
}

export const CreateEventComponentCTA: React.FC<ICreateEventComponentCTA> = ({
  myEventsRef,
  offering,
  input,
  completed,
  eventName,
  myEvents = [],
  added,
  error,
  setError,
}) => {
  const { packageOffering } = offeringTypes
  const isPackage = offering.__typename === packageOffering

  const { isAuthenticated } = useCurrentUser()
  const [registerModal, openRegisterModal] = useState(false)
  const [createEvent, loadingCreateEvent] = useCreateEvent()
  const [handleCreateComponent, loadingAdd] = useAddEventComponent(myEventsRef)

  const createComponent = (eventId: string) => {
    const validateParams = {
      input,
      offering,
      eventsCount: myEvents.length,
      eventName,
    }
    const validationText = isPackage
      ? validateEvendCard(validateParams)
      : validateRoomCard(validateParams)
    setError(validationText)

    if (validationText || loadingCreateEvent || loadingAdd) {
      return
    }

    const variables = {
      eventId,
      offeringId: offering.id,
      input,
    }
    handleCreateComponent(variables, offering.name, completed)
  }

  const previousCheck = async () => {
    let eventId: string
    if (myEvents.length > 0) {
      eventId = myEventsRef.current?.value as string
    } else {
      const createdEvent = await createEvent({
        name: eventName,
        defaultPlusOne: 1,
        defaultKidsAllowed: true,
        tiers: [],
        guestCanPost: true,
        description: '',
        mainImage: '',
        emailCustomText: null,
        isPrivate: true,
        customUrl: null,
      })
      eventId = createdEvent?.id as string
    }

    const existingComponent = flow(
      find((el: MyEvent) => el.id === eventId),
      get('components'),
      some((el: EventComponent) => el.offering.id === offering.id)
    )(myEvents)

    if (existingComponent) {
      // if the component exists it will only be added again if the user accepts
      confirmAlert({
        html: `${offering.name} already exists in your event, do you want to continue adding it?`,
      }).then(response => {
        if (response.value) {
          createComponent(eventId)
        }
      })
    } else {
      // if the component does not exist it is added automatically
      createComponent(eventId)
    }
  }

  return (
    <div className="w-100">
      <SignUpSignInForValidations
        open={registerModal}
        onClose={() => openRegisterModal(false)}
        title="Sign up or sign in to continue"
        text="You must be signed in to continue with your package"
      />

      <div className="d-flex justify-content-between align-items-center">
        <span
          className="fz-13"
          style={{ width: 'fit-content', order: isPackage ? 1 : 0 }}
        >
          {added && (
            <>
              <ImCheckmark2
                size={18}
                color={green}
                style={{ position: 'relative', top: '-3px' }}
              />{' '}
              Added
            </>
          )}
        </span>
        <Button
          disabled={loadingCreateEvent || loadingAdd}
          style={{ order: isPackage ? 0 : 1 }}
          onClick={
            isAuthenticated ? previousCheck : () => openRegisterModal(true)
          }
        >
          {isPackage ? 'Add Package +' : 'Add +'}
        </Button>
      </div>

      {error && (
        <p
          className={`w-100 text-danger fz-12 mb-0 mt-2 ${
            isPackage ? '' : 'text-center'
          }`}
        >
          {error}
        </p>
      )}
    </div>
  )
}
