import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import FormCheck from 'react-bootstrap/FormCheck'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import { v4 as uuidv4 } from 'uuid'
import { MdCancel } from 'react-icons/md'

import { red } from '../../../styles/themeColors'
import { ValidationModal } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { BookingGuest, GuestShort } from '../../../types'
import {
  addFreeGuest,
  editFreeGuest,
  freeGuestsWasUpdated,
  removeFreeGuest,
} from '../../../features/bookingSlice'

interface IguestInfo {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  plusone: boolean
  bookingAddonId?: string
  main?: boolean
}
export const FreeGuests: React.FC<{
  basic: number
  guestShort: GuestShort
}> = ({ basic, guestShort }) => {
  const dispatch = useAppDispatch()
  const {
    availableAddons: { extraFreeGuestAddon },
    freeGuests = [],
    freeGuestsUpdated,
    renderHelper,
    onBehalfOfGuest,
  } = useAppSelector(state => state.booking)

  const [showValidationModal, setShowValidationModal] = useState(false)
  const [btnHelper, setBtnHelper] = useState(() =>
    Array.from({ length: basic }, () => true)
  )
  const [mainGuestHelper, setMainGuestHelper] = useState(true)
  const [guestList, setGuestListInfo] = useState<IguestInfo[]>([])

  useEffect(() => {
    if (renderHelper <= 0) return

    const length = freeGuests?.length
    if (length <= 0 && extraFreeGuestAddon) {
      // If there are no free guests, we add the primary guest
      const id = onBehalfOfGuest?.id ?? uuidv4()
      const guest = {
        firstName: onBehalfOfGuest?.firstName ?? guestShort.firstName,
        lastName: onBehalfOfGuest?.lastName ?? guestShort.lastName,
        email: onBehalfOfGuest?.email ?? guestShort.email ?? '',
        phone: onBehalfOfGuest?.phone ?? guestShort.phone ?? '',
        plusone: false,
      }
      dispatch(
        addFreeGuest({
          id,
          addon: extraFreeGuestAddon,
          guest: guest as BookingGuest,
          mainGuest: true,
        })
      )
      setGuestListInfo(prev => [...prev, { ...guest, id, main: true }])
    }

    if (length > 0 && extraFreeGuestAddon) {
      // If there are free guests, we add them to the current guest list
      // eslint-disable-next-line no-console
      console.log(freeGuests)
      setGuestListInfo(
        freeGuests.map(
          el =>
            ({
              ...el.guest,
              id: el.id,
              bookingAddonId: el?.bookingAddOnId,
              main: !!onBehalfOfGuest || el.guest.email === guestShort.email,
            } as IguestInfo)
        )
      )
    }
    // We only need this to run once so we dont care about the other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraFreeGuestAddon, renderHelper, freeGuests?.length])

  const handleSubmit = (guestInfo: IguestInfo, idx: number) => {
    if (!extraFreeGuestAddon) return

    const id = guestInfo?.id ?? uuidv4()
    const form = document.querySelector(`#form-${idx}`) as HTMLFormElement
    const addingNewGuest = !guestInfo?.id
    const isMainGuest = form.email.value === guestShort.email

    const guest = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value,
      phone: form.phone.value,
      // If the guest is the main guest, we dont allow them to be a +1
      plusone: isMainGuest ? false : form[`check-plusone-${idx}`].checked,
    }

    dispatch(
      addingNewGuest
        ? addFreeGuest({
            id,
            addon: extraFreeGuestAddon,
            guest: guest as BookingGuest,
          })
        : editFreeGuest({
            id: guestInfo.id,
            addon: extraFreeGuestAddon,
            guest: guest as BookingGuest,
            bookingAddOnId: guestInfo.bookingAddonId,
            mainGuest: isMainGuest,
          })
    )

    setGuestListInfo(prev =>
      addingNewGuest
        ? [...prev, { ...guest, id }]
        : prev.map(el =>
            el.id === guestInfo.id
              ? { ...guest, id: guestInfo.id, main: isMainGuest }
              : el
          )
    )
    setBtnHelper(prev => prev.map((el, i) => (i === idx ? true : el)))
    dispatch(freeGuestsWasUpdated(false))
  }

  const handleDelete = (guestInfo: IguestInfo) => {
    dispatch(removeFreeGuest(guestInfo.id))
    setGuestListInfo(prev => prev.filter(el => el.id !== guestInfo.id))
  }

  const dataHasChanged = (idx: number) => {
    if (idx === 0) {
      const email = document.querySelector(`#email-${idx}`) as HTMLInputElement
      setMainGuestHelper(email?.value === guestShort.email)
      const idCheck = `#check-plusone-${idx}`
      const check = document.querySelector(idCheck) as HTMLInputElement
      if (email?.value === guestShort.email) {
        check.checked = false
      }
    }

    if (btnHelper[idx]) {
      setBtnHelper(prev => prev.map((el, i) => (i === idx ? false : el)))
    }

    if (!freeGuestsUpdated) {
      dispatch(freeGuestsWasUpdated(true))
    }
  }

  const forms = Array.from({ length: basic }, (_, idx) => {
    const guest = guestList[idx]
    const isNewGuest = !guest

    return (
      <form
        onSubmit={ev => {
          ev.preventDefault()
          handleSubmit(guest, idx)
        }}
        key={`${idx}-${guest?.id}`}
        id={`form-${idx}`}
      >
        <Table
          borderless
          size="sm"
          data-cy="free-guests"
          className="mb-1 free-guests-form-table"
        >
          {idx === 0 && (
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>+1</th>
                <th>{/** actions */}</th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">
                  First Name <span className="text-danger">*</span>
                </p>
                <FormControl
                  onChange={() => dataHasChanged(idx)}
                  id="firstName"
                  defaultValue={guest?.firstName}
                  required
                />
              </td>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">
                  Last Name <span className="text-danger">*</span>
                </p>
                <FormControl
                  onChange={() => dataHasChanged(idx)}
                  id="lastName"
                  defaultValue={guest?.lastName}
                  required
                />
              </td>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">Email</p>
                <FormControl
                  onChange={() => dataHasChanged(idx)}
                  id={`email-${idx}`}
                  name="email"
                  defaultValue={guest?.email}
                  type="email"
                />
              </td>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">Phone</p>
                <FormControl
                  onChange={() => dataHasChanged(idx)}
                  id="phone"
                  defaultValue={guest?.phone}
                />
              </td>
              <td className="align-middle text-center">
                <span className="d-inline-block d-md-none fwsb pr-1">+1</span>
                <FormCheck
                  className="ml-1"
                  onChange={ev => {
                    if (guestShort.plusOne <= 0) {
                      ev.preventDefault()
                      setShowValidationModal(true)
                      return
                    }
                    dataHasChanged(idx)
                  }}
                  disabled={idx === 0 && mainGuestHelper}
                  defaultChecked={guest?.plusone}
                  custom
                  type="checkbox"
                  id={`check-plusone-${idx}`}
                />
              </td>
              <td className="align-middle">
                <Button
                  size="sm"
                  type="submit"
                  className="rounded-pill m-0 px-4 px-md-2"
                  disabled={btnHelper[idx]}
                >
                  save
                </Button>
              </td>
              <td className="align-middle" style={{ width: '30px' }}>
                {!isNewGuest && (
                  <MdCancel
                    size={20}
                    color={red}
                    title="Delete"
                    className="cursor"
                    style={{ margin: '0' }}
                    onClick={() => handleDelete(guest)}
                  />
                )}
              </td>
            </tr>
            {!guest?.main && guest?.firstName && !guest?.plusone && (
              <tr>
                <td className="text-danger fz-12 text-left pt-0" colSpan={6}>
                  Is this guest attending the event with you? Tick the box if
                  they are your +1
                </td>
              </tr>
            )}
            {!btnHelper[idx] && (
              <tr>
                <td className="text-danger fz-12 text-left pt-0" colSpan={6}>
                  * unsaved changes
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </form>
    )
  })

  return (
    <>
      <ValidationModal
        text="You have reached the maximum number of +1.<br />"
        textClass="fz-18 pt-2"
        show={showValidationModal}
        onHide={() => setShowValidationModal(false)}
      />
      {forms}
    </>
  )
}
