import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import LazyLoad from 'react-lazyload'
import { Helmet } from 'react-helmet'

import getInTouch from '../../assets/images/getInTouch.jpg'

import { Container1st100Groups } from './style'
import { ContactForm } from './components'

interface I1st100 {
  intersectTarget?: React.RefObject<HTMLElement>
}

export const Section1st100Groups: React.FC<I1st100> = ({ intersectTarget }) => {
  return (
    <Container1st100Groups ref={intersectTarget}>
      <Helmet>
        <title>Kiss & Tell | Sign Up for a 5% Discount</title>
        <meta
          name="description"
          content="Receive a 5% discount on vendors, travel upgrades, and complimentary concierge service!"
        />
      </Helmet>
      <Container className="pb-2 pt-5 py-md-5">
        <Row>
          <Col xs={12} className="text-center pb-3">
            <h2>Be one of the #1st100Groups to book on Kiss & Tell</h2>
            <span className="line bg-white my-3" />
            <p>
              Receive a 5% discount on vendors, travel upgrades, and
              complimentary concierge service!
            </p>
          </Col>
          <Col xs={12} md={7}>
            <LazyLoad height={300} once>
              <img src={getInTouch} alt="Get in Touch" className="photo" />
            </LazyLoad>
          </Col>
          <Col
            xs={12}
            md={5}
            className="d-flex align-items-end justify-content-center pb-4"
          >
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Container1st100Groups>
  )
}
