import React from 'react'
import { Helmet } from 'react-helmet'

import collage from '../../assets/images/Landingpages/trip_collage.png'
import { Wave } from '../../components'
import { useMoveToTop } from '../../common'
import { pink, salmon } from '../../styles/themeColors'
import {
  ComparisonSection,
  CreditsSection,
  FormSection,
  VideoSection,
} from '../Landingpages/components'

import { Header } from './components'

export const EngagementTrip: React.FC = () => {
  useMoveToTop()

  return (
    <section className="public-container public-page">
      <Helmet>
        <title>Kiss & Tell | Engagement trip gift with purchase</title>
        <meta
          name="description"
          content="Kiss & Tell Engagement trip gift with purchase landing page"
        />
      </Helmet>
      <Header />
      <Wave
        color={salmon}
        style={{
          zIndex: 3,
          rotate: '180deg',
          position: 'relative',
          top: '-15px',
        }}
      />
      <ComparisonSection />
      <Wave
        color={pink}
        style={{
          position: 'relative',
          bottom: '-1px',
          zIndex: 3,
        }}
      />
      <FormSection
        formName="engagement-trip"
        referredBy="Engagement Trip Landing Page"
      />
      <Wave
        color={pink}
        style={{
          transform: 'rotate(180deg)',
          position: 'relative',
          top: '-1px',
        }}
      />
      <CreditsSection
        title="Gain access to wedding credits"
        collage={collage}
        collageClass="p-3"
      />

      <VideoSection />
    </section>
  )
}
