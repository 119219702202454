import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Card from 'react-bootstrap/Card'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import { salmon } from '../../styles/themeColors'
import { SiteLogo } from '../../components/customIcons'
import { Signin } from '../../components/SigninSignup/Signin'
import { Signup } from '../../components/SigninSignup/Signup'
import { SignInUpContainer } from '../../components/SigninSignup/style'
import { myHub } from '../../common/constants'

export const LoginPage: React.FC = () => {
  const history = useHistory()
  const close = () => history.push(myHub)

  return (
    <Card
      style={{ maxWidth: '600px', width: '98%' }}
      className="mx-auto my-3 my-lg-5"
    >
      <Helmet>
        <title>Kiss & Tell | Login</title>
        <meta name="description" content="Kiss & Tell Login page" />
      </Helmet>
      <SignInUpContainer>
        <div className="text-center py-4">
          <SiteLogo width="70px" height="52px" color={salmon} />
          <h4 className="m-1">Start chatting and get updates</h4>
          <span className="fz-13 tc-lgray">
            Sign in for event details & group chat
          </span>
        </div>
        <Tabs defaultActiveKey="login" id="sdfsdfsd">
          <Tab eventKey="login" title="Login">
            <Signin onClose={close} />
          </Tab>
          <Tab eventKey="register" title="Register">
            <Signup onClose={close} />
          </Tab>
        </Tabs>
      </SignInUpContainer>
    </Card>
  )
}
