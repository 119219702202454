import styled from 'styled-components'

import { salmon, darkGray, lightGray, gray } from '../../styles/themeColors'

export const FiltersContainer = styled.section`
  position: relative;
  z-index: 100;
  @media (max-width: 575px) {
    &.close-search-filters {
      & .location-filter-container {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s linear;
      }
    }
    &.open-search-filters {
      & .location-filter-container {
        max-height: 810px;
        overflow: hidden;
        transition: max-height 0.4s linear;
      }
    }
  }
`

export const Filter = styled.section`
  align-items: flex-end;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0.5rem 0 0 0;
  & .filter-control {
    position: relative;
    & label {
      color: ${gray};
      display: block;
      font-size: 13px;
      font-weight: 600;
      margin-top: 5px;
    }
    & input {
      border: 1px solid rgba(164, 164, 165, 0.8);
      border-radius: 5px;
      color: ${darkGray};
      font-size: 15px;
      font-weight: 400;
      height: 36px;
      outline: none !important;
      &.text {
        padding: 0 5px;
        width: 280px;
      }
      &.number {
        padding: 0 10px;
        width: 140px;
      }
    }
  }
  @media (max-width: 1050px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    & .filter-control {
      margin: 0 0 0 5%;
      width: 40%;
    }
    & .search-action {
      display: block;
      margin: 15px 0 0 5%;
    }
  }
  @media (max-width: 740px) {
    & .filter-control {
      margin: 0 0 0 1%;
      width: 48%;
    }
  }
  @media (max-width: 680px) {
    overflow: hidden;
    & .filter-control {
      margin: 0 2%;
      text-align: center;
      width: 96%;
    }
    & .search-action {
      margin: 15px auto;
    }
    &.close-filter-options {
      height: 0px;
    }
    &.open-filter-options {
      height: 414px;
    }
  }
`

// Details page
export const Details = styled.div`
  padding-right: 10px;
  & table {
    border-bottom: 1px solid ${lightGray};
    border-top: 1px solid ${lightGray};
    & tr {
      & td {
        padding: 6px;
        min-width: 190px;
        &:first-child {
          color: ${salmon};
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }
  & .icons {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    & div {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 30px 0 0;
      & img {
        height: 50px;
        width: 50px;
      }
      & p {
        margin: 0;
      }
    }
  }
  @media (max-width: 991px) {
    margin: 20px 0 0 0;
    width: 100%;
  }
  @media (max-width: 500px) {
    & .icons {
      justify-content: space-between;
      & div {
        margin: 0 10px 0 0;
      }
    }
    & .location-details-description .table-responsive table tbody {
      & tr {
        display: flex;
        flex-wrap: wrap;
        & td {
          padding-left: 0 !important;
          padding-right: 0 !important;
          width: 100%;
          &:first-child {
            border-bottom: none;
            padding-top: 15px !important;
          }
          &:last-child {
            border-top: none;
            padding-bottom: 15px !important;
            padding-top: 0 !important;
          }
        }
      }
    }
  }
`

export const SingleRoomCard = styled.article`
  border: 1px solid rgba(219, 219, 219, 0.8);
  border-radius: 4px;
  margin: 0 14px 20px 0;
  max-width: 250px;
  width: 96%;
  & .cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 135px;
    margin: 0 0 33px 0;
    position: relative;
    width: 100%;
    & span {
      align-items: center;
      background-color: ${salmon};
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 #c3c3c3;
      bottom: -30px;
      display: flex;
      height: 60px;
      justify-content: center;
      position: absolute;
      right: 12px;
      width: 60px;
    }
  }
  & .occupancy {
    & .cursor {
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }
  @media (max-width: 991px) {
    margin: 0 0 20px 0;
  }
`

export const ProvidersListConatiner = styled.section`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & .providers-map {
    height: 95vh;
    position: sticky;
    top: 65px;
    width: calc(100% - 820px);
  }
  @media (max-width: 991px) {
    overflow-y: hidden;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    & .providers-map {
      height: 96%;
      left: 0;
      position: absolute;
      top: 60px;
      touch-action: pan-x pan-y;
      width: 100%;
      z-index: 1;
    }
  }
`

export const List = styled.section`
  & .provider-list {
    padding-left: 15px;
    padding-right: 15px;
    width: 800px;
  }
  @media (max-width: 991px) {
    background-color: white;
    border-radius: 15px 15px 0 0;
    bottom: -80.5vh;
    height: 88vh;
    left: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    overflow-y: hidden;
    transition: bottom 0.2s ease-in-out;
    width: 100%;
    z-index: 2;
    & .infinite-scroll-component__outerdiv {
      width: 100% !important;
    }
    & .provider-list {
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
    }
    &.open-list {
      bottom: -1.4vh;
      overflow-y: scroll;
      position: absolute;
      transition: bottom 0.2s ease-in-out;
    }
  }
`
