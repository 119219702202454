import styled from 'styled-components'

export const TagsContainer = styled.div`
  display: block;
  margin: 5px auto 0 auto;
  width: 100%;
`

export const Tag = styled.span`
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 3px 2px 3px;
  padding: 0px 10px 2px 10px;
  &.normal {
    border-radius: 20px;
    font-size: 15px;
    padding: 0px 15px 3px 15px;
  }
`
