/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { addonMetadata } from '../common/constants'
import { categorizeOfferingAddons } from '../pages/Booking/components/bookingFunctions'
import {
  BookingGuest,
  Event,
  EventComponent,
  IBookingGuest,
  ICategorizedAddons,
  IcurrentAddons,
  IFreeBookingGuest,
  MyInformation,
  OfferingAddOnOptions,
  OfferingAddOnOptionsWithType,
  OfferingAddOns,
} from '../types'

import {
  cleanupStorage,
  getPublicExtraNights,
  getPublicFreeGuests,
  getPublicPaidGuests,
  getStoredComponent,
  setPublicExtraNights,
  setPublicFreeGuests,
  setPublicPaidGuests,
  setStoredComponent,
} from './utils'

const initialState: {
  renderHelper: number
  totalPrice?: number
  event?: Event
  component?: EventComponent
  customBookingDate: string | null
  customBookingDuration: number | null
  availableAddons: ICategorizedAddons
  // extra nights
  extraNights?: OfferingAddOnOptionsWithType[]
  extraNightsUpdated?: boolean
  // free guests
  freeGuests?: IFreeBookingGuest[]
  freeGuestsUpdated?: boolean
  // paid guests
  paidGuests?: IBookingGuest[]
  paidGuestsUpdated?: boolean
  // list to delete
  addonsIdsToDelete?: string[]
  // my information section in booking
  myInformation?: MyInformation
} = {
  renderHelper: 0,
  totalPrice: 0,
  component: getStoredComponent(),
  availableAddons: {},
  extraNights: getPublicExtraNights(),
  freeGuests: getPublicFreeGuests(),
  paidGuests: getPublicPaidGuests(),
  customBookingDate: null,
  customBookingDuration: null,
}

const publicBookingSlice = createSlice({
  name: 'booking_slice',
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<Event>) => {
      state.event = action.payload
    },
    setComponent: (state, action: PayloadAction<EventComponent>) => {
      state.component = action.payload
      setStoredComponent(action.payload)
    },
    setTotalPrice: (state, action: PayloadAction<number>) => {
      state.totalPrice = action.payload
    },
    setCustomBookingRange: (
      state,
      action: PayloadAction<{ date: string | null; duration: number | null }>
    ) => {
      state.customBookingDate = action.payload.date
      state.customBookingDuration = action.payload.duration
    },
    setAvailableAddons: (
      state,
      action: PayloadAction<{ addons?: OfferingAddOns[] }>
    ) => {
      state.availableAddons = categorizeOfferingAddons(
        action.payload.addons ?? []
      )
    },
    setCurrentAddons: (state, action: PayloadAction<IcurrentAddons>) => {
      const currentAddons = action.payload

      // prepopulate extra nights with current booking addons
      const extraNights: OfferingAddOnOptionsWithType[] = []
      if (currentAddons.extraNightsBefore) {
        const option = currentAddons.extraNightsBefore.offeringAddOnOption
        extraNights.push({
          ...(option as OfferingAddOnOptions),
          type: 'before',
          bookingAddOnId: currentAddons.extraNightsBefore.id,
        })
      }
      if (currentAddons.extraNightsAfter) {
        const option = currentAddons.extraNightsAfter.offeringAddOnOption
        extraNights.push({
          ...(option as OfferingAddOnOptions),
          type: 'after',
          bookingAddOnId: currentAddons.extraNightsAfter.id,
        })
      }
      state.extraNights = extraNights
      setPublicExtraNights(extraNights)

      // prepopulate free guests with current booking addons
      if (currentAddons.freeGuests) {
        state.freeGuests = currentAddons.freeGuests.map(el => ({
          id: el.id,
          addon: el.offeringAddOn,
          guest: {
            firstName: el.bookingGuest?.firstName,
            lastName: el.bookingGuest?.lastName,
            email: el.bookingGuest?.email,
            phone: el.bookingGuest?.phone,
            plusone: el.bookingGuest?.plusone ?? false,
          } as BookingGuest,
          bookingAddOnId: el.id,
        }))
        setPublicFreeGuests(state.freeGuests)
      }

      // prepopulate paid guests with current booking addons
      if (currentAddons.paidGuests) {
        state.paidGuests = currentAddons.paidGuests.map(el => ({
          id: el.id,
          option: el.offeringAddOnOption as OfferingAddOnOptions,
          guest: {
            firstName: el.bookingGuest?.firstName,
            lastName: el.bookingGuest?.lastName,
            email: el.bookingGuest?.email,
            phone: el.bookingGuest?.phone,
            plusone: el.bookingGuest?.plusone ?? false,
          } as BookingGuest,
          bookingAddOnId: el.id,
          type:
            el.offeringAddOn.metadata.subtype === addonMetadata.guest
              ? 'adult'
              : 'kid',
        }))
        setPublicPaidGuests(state.paidGuests)
      }

      state.renderHelper += 1
    },
    // extra nights
    addExtraNight: (
      state,
      action: PayloadAction<{
        option?: OfferingAddOnOptions
        existing?: string
        type: 'before' | 'after'
      }>
    ) => {
      // remove existing extra night by OfferingAddOnId
      if (action.payload.existing) {
        state.extraNights = state.extraNights?.filter(addon => {
          if (addon?.bookingAddOnId) {
            state.addonsIdsToDelete?.push(addon.bookingAddOnId)
          }
          return addon.offeringAddOnId !== action.payload.existing
        })
      }

      if (action.payload.option) {
        state.extraNights?.push({
          ...action.payload.option,
          type: action.payload.type,
        })
        setPublicExtraNights(state.extraNights)
      }
    },
    removeExtraNight: (
      state,
      action: PayloadAction<{
        option: OfferingAddOnOptions
        bookingAddOnId?: string
      }>
    ) => {
      state.extraNights = state.extraNights?.filter(
        addon => addon.id !== action.payload.option.id
      )
      if (action.payload.bookingAddOnId) {
        state.addonsIdsToDelete?.push(action.payload.bookingAddOnId)
      }
      setPublicExtraNights(state.extraNights)
    },
    extraNightWasUpdated: (state, action: PayloadAction<boolean>) => {
      state.extraNightsUpdated = action.payload
    },
    // free guests
    addFreeGuest: (state, action: PayloadAction<IFreeBookingGuest>) => {
      state.freeGuests?.push(action.payload)
      setPublicFreeGuests(state.freeGuests)
    },
    editFreeGuest: (state, action: PayloadAction<IFreeBookingGuest>) => {
      state.freeGuests = state.freeGuests?.map(guest => {
        if (guest.id === action.payload.id) {
          return action.payload
        }
        return guest
      })
      setPublicFreeGuests(state.freeGuests)
    },
    freeGuestsWasUpdated: (state, action: PayloadAction<boolean>) => {
      state.freeGuestsUpdated = action.payload
    },
    removeFreeGuest: (state, action: PayloadAction<string>) => {
      state.freeGuests = state.freeGuests?.filter(addon => {
        if (addon.bookingAddOnId === action.payload) {
          state.addonsIdsToDelete?.push(action.payload)
        }
        return addon.id !== action.payload
      })
      setPublicFreeGuests(state.freeGuests)
    },
    // paid guests
    addPaidGuest: (state, action: PayloadAction<IBookingGuest>) => {
      state.paidGuests?.push(action.payload)
      setPublicPaidGuests(state.paidGuests)
    },
    paidGuestsWasUpdated: (state, action: PayloadAction<boolean>) => {
      state.paidGuestsUpdated = action.payload
    },
    removePaidGuest: (state, action: PayloadAction<IBookingGuest>) => {
      state.paidGuests = state.paidGuests?.filter(addon => {
        if (addon.bookingAddOnId === action.payload.bookingAddOnId) {
          state.addonsIdsToDelete?.push(action.payload.bookingAddOnId as string)
        }
        return addon.id !== action.payload.id
      })
      setPublicPaidGuests(state.paidGuests)
    },
    cleanAddonIdsToDelete: state => {
      state.addonsIdsToDelete = []
    },
    // my information
    setMyInfo: (state, action: PayloadAction<MyInformation>) => {
      state.myInformation = action.payload
    },
    cleanBooking: state => {
      cleanupStorage()
      return {
        event: state.event,
        availableAddons: {},
        customBookingDate: null,
        customBookingDuration: null,
        renderHelper: 0,
      }
    },
  },
})

export const {
  cleanBooking, // clear all booking data
  setEvent,
  setComponent,
  cleanAddonIdsToDelete,
  setTotalPrice,
  setAvailableAddons,
  setCurrentAddons,
  // extra nights
  addExtraNight,
  extraNightWasUpdated,
  removeExtraNight,
  setCustomBookingRange,
  // free guests
  addFreeGuest,
  editFreeGuest,
  freeGuestsWasUpdated,
  removeFreeGuest,
  // paid guests
  addPaidGuest,
  paidGuestsWasUpdated,
  removePaidGuest,
  setMyInfo,
} = publicBookingSlice.actions
export default publicBookingSlice.reducer
