import gql from 'graphql-tag'

import { ROOM_FIELDS_FRAG, PACKAGE_FIELDS_FRAG } from './locationsQueries'
import { IMAGE_FIELDS_FRAG, PACKAGE_ADDONS_FIELDS } from './general'
import { FLIGHT_DATA } from './rsvpQueries'

// new fragment to use in guestlist manager bacause the process to get bookings is important
const GUEST_FIELDS_WITH_BOOKING_FRAG = gql`
  fragment GuestFieldsWithBooking on Guest {
    avatarUrl
    canPost
    email
    event {
      id
      name
    }
    firstName
    id
    kidsAllowed
    lastName
    phone
    plusOne
    status
    seen
    preInvitationSent
    reminderSentAt
    tiers {
      id
      name
    }
    flightInformation {
      ...flightData
    }
    booking {
      id
      status
      guest {
        id
      }
      bookingAddOn {
        id
        bookingGuest {
          email
          firstName
          lastName
        }
      }
      eventComponent {
        id
        offering {
          __typename
          ... on PackageOffering {
            provider {
              location
            }
          }
          ... on RoomOffering {
            provider {
              location
            }
          }
        }
      }
    }
  }
  ${FLIGHT_DATA}
`

export const GUEST_FIELDS_FRAG = gql`
  fragment GuestFields on Guest {
    avatarUrl
    canPost
    email
    event {
      id
      name
    }
    firstName
    id
    kidsAllowed
    lastName
    phone
    plusOne
    status
    tiers {
      id
      name
    }
  }
`

export const EVENT_FIELDS_FRAG = gql`
  fragment EventFields on MyEvent {
    guests {
      ...GuestFields
    }
    defaultKidsAllowed
    defaultPlusOne
    guestCanPost
    id
    name
    customUrl
    isPrivate
    description
    tiers {
      id
      name
    }
    mainImage
  }
  ${GUEST_FIELDS_FRAG}
`

export const MESSAGES_FIELDS = gql`
  fragment MessagesFields on NegotiationMessage {
    body
    id
    insertedAt
    item {
      deleted
      id
      price
      title
      eventComponentId
    }
    user {
      avatarUrl
      firstName
      id
      lastName
    }
    seen
  }
`

export const NEGOTIATION_ITEM_FIELDS = gql`
  fragment negotiationItemFields on NegotiationItem {
    deleted
    id
    master
    price
    title
  }
`

export const CREATE_EVENT = gql`
  mutation createEvent($input: EventInput!) {
    createEvent(input: $input) {
      result {
        id
        defaultKidsAllowed
        defaultPlusOne
        guestCanPost
        guestCount
        name
        tiers {
          id
          name
        }
        description
        emailCustomText
        mainImage
      }
      errors {
        key
        message
      }
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation updateEvent($id: ID!, $input: EventInput!) {
    updateEvent(id: $id, input: $input) {
      result {
        id
        name
        defaultKidsAllowed
        defaultPlusOne
        description
        emailCustomText
        mainImage
        isPrivate
        customUrl
      }
      errors {
        key
        message
      }
    }
  }
`

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_MY_EVENT = gql`
  query getMyEvent($id: ID!, $tierIds: [ID!]) {
    me {
      id
      ... on Customer {
        myEvents(id: $id) {
          defaultKidsAllowed
          defaultPlusOne
          guestCanPost
          mainImage
          id
          name
          tiers {
            id
            name
          }
          guests(tierIds: $tierIds) {
            ...GuestFields
          }
          paidComponents(eventId: $id)
        }
      }
    }
  }
  ${GUEST_FIELDS_FRAG}
`
export const GET_MY_EVENT_INFO = gql`
  query getMyEventInfo($id: ID!) {
    me {
      id
      ... on Customer {
        myEvents(id: $id) {
          ...EventFields
          paidComponents(eventId: $id)
          emailCustomText
          components {
            main
            date
            offering {
              __typename
              ... on PackageOffering {
                provider {
                  name
                  address
                  location
                  mainImage {
                    ...ImageFields
                  }
                }
              }
              ... on RoomOffering {
                provider {
                  name
                  address
                  location
                  mainImage {
                    ...ImageFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${EVENT_FIELDS_FRAG}
  ${IMAGE_FIELDS_FRAG}
`
export const GET_ALL_EVENTS = gql`
  query getAllEvents {
    me {
      id
      ... on Customer {
        myEvents {
          defaultKidsAllowed
          defaultPlusOne
          guestCanPost
          id
          name
          mainImage
          tiers {
            id
            name
          }
          guestCount
        }
        events {
          id
          name
          mainImage
          guestProfile {
            id
            canPost
          }
          host {
            avatarUrl
          }
          tiers {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_MY_EVENTS = gql`
  query getMyEvents {
    me {
      id
      ... on Customer {
        myEvents {
          id
          name
          components {
            offering {
              __typename
              ... on PackageOffering {
                id
              }
              ... on RoomOffering {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const GET_EVENT_BALANCES = gql`
  query getEventBalances($eventId: ID!, $providers: [ID!]!) {
    me {
      id
      ... on Customer {
        id
        myEvents(id: $eventId) {
          id
          balances(eventId: $eventId, providers: $providers) {
            balance
            provider
            lastMessage {
              seen
              user {
                id
              }
            }
            quotes {
              eventComponent {
                id
                status
                date
                offering {
                  __typename
                }
              }
              items {
                deleted
                price
              }
            }
          }
        }
      }
    }
  }
`

export const GET_EVENT_COMPONENTS = gql`
  query getEventComponents($id: ID) {
    me {
      id
      ... on Customer {
        id
        myEvents(id: $id) {
          id
          name
          tiers {
            id
            name
          }
          components(filterRecent: true) {
            main
            capacity
            date
            duration
            id
            guestPays
            tiers {
              id
            }
            offering {
              __typename
              ... on PackageOffering {
                ...PackageFields
              }
              ... on RoomOffering {
                ...RoomFields
              }
            }
            status
            main
            items {
              price
              deleted
            }
            packageCustomization {
              ...PackageAddonsFields
            }
          }
        }
      }
    }
  }
  ${PACKAGE_FIELDS_FRAG}
  ${ROOM_FIELDS_FRAG}
  ${PACKAGE_ADDONS_FIELDS}
`

export const CREATE_EVENT_COMPONENT = gql`
  mutation createEventComponent(
    $eventId: ID!
    $offeringId: ID!
    $input: EventComponentInput!
  ) {
    createEventComponent(
      eventId: $eventId
      offeringId: $offeringId
      input: $input
    ) {
      result {
        capacity
        date
        duration
        id
        tiers {
          id
        }
        guestPays
        offering {
          __typename
          ... on PackageOffering {
            ...PackageFields
          }
          ... on RoomOffering {
            ...RoomFields
          }
        }
        status
        main
      }
      errors {
        key
        message
      }
    }
  }
  ${PACKAGE_FIELDS_FRAG}
  ${ROOM_FIELDS_FRAG}
`

export const UPDATE_EVENT_COMPONENT = gql`
  mutation updateEventComponent($id: ID!, $input: EventComponentInput!) {
    updateEventComponent(id: $id, input: $input) {
      result {
        capacity
        date
        duration
        id
        guestPays
        tiers {
          id
        }
        offering {
          __typename
          ... on PackageOffering {
            ...PackageFields
          }
          ... on RoomOffering {
            ...RoomFields
          }
        }
        status
        main
      }
      errors {
        key
        message
      }
    }
  }
  ${PACKAGE_FIELDS_FRAG}
  ${ROOM_FIELDS_FRAG}
`

export const ALIAS_EVENT_COMPONENT = gql`
  mutation aliasEventComponent($id: ID!, $input: EventComponentInput!) {
    updateEventComponent(id: $id, input: $input) {
      result {
        alias
        id
      }
      errors {
        key
        message
      }
    }
  }
`

export const SET_MAIN_COMPONENT = gql`
  mutation setMainComponent($id: ID!) {
    setMainComponent(id: $id) {
      result {
        id
        main
      }
      errors {
        key
        message
      }
    }
  }
`

export const TRIGGER_EVENT_COMPONENT_EVENT = gql`
  mutation triggerEventComponentEvent($id: ID, $event: EventComponentEvent) {
    triggerEventComponentEvent(id: $id, event: $event) {
      result {
        capacity
        date
        duration
        id
        offering {
          __typename
          ... on PackageOffering {
            ...PackageFields
          }
          ... on RoomOffering {
            ...RoomFields
          }
        }
        status
        main
      }
      errors {
        key
        message
      }
    }
  }
  ${PACKAGE_FIELDS_FRAG}
  ${ROOM_FIELDS_FRAG}
`

export const ADD_ROOMS_TO_NEGOTIATION = gql`
  mutation addRoomsToNegotiation($eventId: ID!, $providerUrl: String!) {
    addRoomsToNegotiation(eventId: $eventId, providerUrl: $providerUrl) {
      result {
        eventId
        providerUrl
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_NEGOTIATION_MESSAGES = gql`
  query getNegotiationMessages(
    $eventId: ID!
    $providerId: ID!
    $cursor: String
  ) {
    me {
      id
      ... on Customer {
        id
        myEvents(id: $eventId) {
          id
          negotiation(eventId: $eventId, providerId: $providerId) {
            quotes {
              eventComponent {
                id
                offering {
                  __typename
                  ... on PackageOffering {
                    name
                  }
                  ... on RoomOffering {
                    name
                  }
                }
              }
            }
            messages(cursor: { cursor: $cursor, limit: 15 }) {
              cursor
              entries {
                ...MessagesFields
              }
            }
          }
        }
      }
    }
  }
  ${MESSAGES_FIELDS}
`

export const GET_NEGOTIATION_QUOTES = gql`
  query getNegotiationQuotes($eventId: ID!, $providerId: ID!) {
    me {
      id
      ... on Customer {
        id
        myEvents(id: $eventId) {
          id
          negotiation(eventId: $eventId, providerId: $providerId) {
            balance
            provider {
              id
              name
              mainImage {
                ...ImageFields
              }
              vendor {
                avatarUrl
              }
            }
            event {
              id
              name
            }
            quotes {
              eventComponent {
                id
                status
                date
                alias
                duration
                offering {
                  __typename
                  ... on PackageOffering {
                    addOns {
                      id
                      frequency
                      label
                      price
                      mandatory
                      offeringAddOnOptions {
                        id
                        description
                        label
                        price
                      }
                    }
                  }
                }
                packageCustomization {
                  ...PackageAddonsFields
                }
              }
              items {
                ...negotiationItemFields
              }
            }
          }
        }
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
  ${NEGOTIATION_ITEM_FIELDS}
  ${PACKAGE_ADDONS_FIELDS}
`

export const EVENT_COMPONENT_SUBSCRIPTION = gql`
  subscription OnEventComponentsUpdate($eventId: ID!) {
    eventComponentsUpdate(eventId: $eventId) {
      ...PackageAddonsFields
    }
  }
  ${PACKAGE_ADDONS_FIELDS}
`

export const EVENT_COMPONENT_SUBSCRIPTION_DELETE = gql`
  subscription OnEventComponentsAddonsDelete($eventId: ID!) {
    eventComponentsAddonsDelete(eventId: $eventId) {
      id
      eventComponentId
    }
  }
`

export const NEGOTIATION_MESSAGES_SUBSCRIPTION = gql`
  subscription OnNegotiationMessages($eventId: ID!, $providerId: ID!) {
    negotiationMessages(eventId: $eventId, providerId: $providerId) {
      ...MessagesFields
    }
  }
  ${MESSAGES_FIELDS}
`

export const GET_NEGOTIATIONS_PREVIEW = gql`
  query getNegotiationsPreview($eventId: ID!, $cursor: String) {
    me {
      id
      ... on Customer {
        id
        myEvents(id: $eventId) {
          id
          negotiations(cursor: { cursor: $cursor, limit: 5 }) {
            cursor
            entries {
              event {
                id
                name
              }
              lastUpdate
              provider {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const CREATE_NEGOTIATION_MESSAGE = gql`
  mutation createNegotiationMessage(
    $eventId: ID!
    $input: NegotiationMessageInput!
    $providerId: ID!
  ) {
    createNegotiationMessage(
      eventId: $eventId
      input: $input
      providerId: $providerId
    ) {
      result {
        ...MessagesFields
      }
      errors {
        key
        message
      }
    }
  }
  ${MESSAGES_FIELDS}
`

export const CREATE_NEGOTIATION_ITEM = gql`
  mutation createNegotiationItem(
    $eventComponentId: ID!
    $input: NegotiationItemInput!
  ) {
    createNegotiationItem(eventComponentId: $eventComponentId, input: $input) {
      result {
        ...negotiationItemFields
      }
      errors {
        key
        message
      }
    }
  }
  ${NEGOTIATION_ITEM_FIELDS}
`

export const DELETE_NEGOTIATION_ITEM = gql`
  mutation deleteNegotiationItem($negotiationItemId: ID!) {
    deleteNegotiationItem(negotiationItemId: $negotiationItemId) {
      result {
        ...negotiationItemFields
      }
      errors {
        key
        message
      }
    }
  }
  ${NEGOTIATION_ITEM_FIELDS}
`

export const INITIATE_HOST_PAYMENT = gql`
  mutation initiateHostPayment(
    $amount: Decimal!
    $eventId: ID!
    $providerId: ID!
  ) {
    initiateHostPayment(
      eventId: $eventId
      amount: $amount
      providerId: $providerId
    ) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

export const NEGOTIATION_BALANCE = gql`
  subscription OnBalanceUpdates($eventId: ID!, $providerId: ID!) {
    balanceUpdates(eventId: $eventId, providerId: $providerId) {
      balance
    }
  }
`

export const GET_FEED_COMPONENTS = gql`
  query feedComponentGroups($eventIds: [ID!], $cursor: String) {
    feedComponentGroups(
      eventIds: $eventIds
      cursor: { cursor: $cursor, limit: 10 }
    ) {
      entries {
        event {
          id
          host {
            avatarUrl
            firstName
          }
        }
        components {
          capacity
          date
          duration
          id
          status
          alias
          main
          bookingCount
          booking {
            id
            status
          }
          packageCustomization {
            ...PackageAddonsFields
          }
          offering {
            __typename
            ... on PackageOffering {
              name
              intro
              description
              mainImage {
                ...ImageFields
              }
              images {
                ...ImageFields
              }
              provider {
                mainImage {
                  ...ImageFields
                }
                location
                id
                address
                url
                name
              }
            }
            ... on RoomOffering {
              name
              description
              mainImage {
                ...ImageFields
              }
              images {
                ...ImageFields
              }
              provider {
                mainImage {
                  ...ImageFields
                }
                location
                id
                address
                url
                name
              }
              price
              room {
                beds
                occupancy
                size
              }
            }
          }
        }
      }
      cursor
    }
  }
  ${IMAGE_FIELDS_FRAG}
  ${PACKAGE_ADDONS_FIELDS}
`

export const ASSIGN_TIER_TO_EVENT_COMPONENT = gql`
  mutation addTierToEventComponent($id: ID!, $tierId: ID!) {
    addTierToEventComponent(id: $id, tierId: $tierId) {
      result {
        id
        tiers {
          id
          name
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const REMOVE_TIER_FROM_EVENT_COMPONENT = gql`
  mutation removeTierFromEventComponent($id: ID!, $tierId: ID!) {
    removeTierFromEventComponent(id: $id, tierId: $tierId) {
      result {
        id
        tiers {
          id
          name
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const MARK_MESSAGES = gql`
  mutation markMessagesAsSeen($eventId: ID!, $providerId: ID!) {
    markMessagesAsSeen(eventId: $eventId, providerId: $providerId) {
      result {
        marked
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_GUEST_LIST_BY_TIERID = gql`
  query getGuestListByTierId($tierId: ID, $eventId: ID) {
    guestList(tierId: $tierId, eventId: $eventId) {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`

export const GET_GUESTLIST_WITH_BOOKINGS = gql`
  query getGuestlistWithBookings($id: ID!, $tierIds: [ID!]) {
    me {
      id
      ... on Customer {
        myEvents(id: $id) {
          defaultKidsAllowed
          defaultPlusOne
          guestCanPost
          mainImage
          id
          name
          tiers {
            id
            name
          }
          guests(tierIds: $tierIds) {
            ...GuestFieldsWithBooking
            guests {
              ...GuestFieldsWithBooking
            }
          }
          paidComponents(eventId: $id)
        }
      }
    }
    availablePackages(eventId: $id) {
      id
      alias
      offering {
        __typename
        ... on PackageOffering {
          name
        }
      }
    }
  }
  ${GUEST_FIELDS_WITH_BOOKING_FRAG}
`

export const SAVE_PACKAGE_CUSTOMIZATION = gql`
  mutation savePackageCustomization($input: PackageAddonsInput!) {
    savePackageCustomization(input: $input) {
      result {
        id
        eventComponentId
        location
        offeringAddOn {
          id
        }
        offeringAddOnOption {
          id
        }
        time
      }
      errors {
        key
        message
      }
    }
  }
`

export const EDIT_PACKAGE_CUSTOMIZATION = gql`
  mutation editPackageCustomization($id: ID!, $input: PackageAddonsInput!) {
    editPackageCustomization(id: $id, input: $input) {
      result {
        id
        eventComponentId
        location
        offeringAddOn {
          id
        }
        offeringAddOnOption {
          id
        }
        time
      }
      errors {
        key
        message
      }
    }
  }
`

export const DELETE_PACKAGE_CUSTOMIZATION = gql`
  mutation deletePackageCustomization($id: ID!) {
    deletePackageCustomization(id: $id) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_AVAILABLE_PACKAGES = gql`
  query getAvailablePackages($eventId: ID!, $guestId: ID) {
    availablePackages(eventId: $eventId, guestId: $guestId) {
      id
      main
      date
      alias
      eventId
      capacity
      offering {
        __typename
        ... on PackageOffering {
          name
          mainImage {
            ...ImageFields
          }
          price
          provider {
            name
          }
          package {
            basePrice
          }
        }
      }
      customTexts
      bookingCount
      guestPays
      tiers {
        id
        name
      }
      items {
        price
        deleted
      }
      packageCustomization {
        ...PackageAddonsFields
      }
    }
  }
  ${PACKAGE_ADDONS_FIELDS}
  ${IMAGE_FIELDS_FRAG}
`
