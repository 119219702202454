import React, { useState, useRef, useEffect } from 'react'
import max from 'lodash/fp/max'
import toInteger from 'lodash/fp/toInteger'
import { Moment } from 'moment'
import { MdMoreHoriz } from 'react-icons/md'

import { SinglePicker } from '../../components'
import { getImageUrl } from '../../common'
import { IEventCard } from '../../types'

import {
  EventPrices,
  OfferingModal,
  CreateEventComponentCTA,
} from './components'

import { getOfferingNumericValue } from '.'
import '../../styles/eventcards-styles.sass'

export const EventCard: React.FC<IEventCard> = ({
  eventData,
  groupSizeProp,
  myEvents,
  idx,
}) => {
  const [error, setError] = useState<string>()
  const [showModal, setShowModal] = useState(false)
  const [added, setAdded] = useState(false)
  const [capacity, setCapacity] = useState<string | number>(-1)
  const [eventDate, setEventdate] = useState('')
  const [eventName, setEventName] = useState('')
  const [defaultEvent, setDefaultEvent] = useState('')
  const [innerGroupSize, setInnerGroupSize] = useState<number>()
  const myEventsRefEventCard = useRef<HTMLSelectElement>(null)

  const baseGuestNumber = getOfferingNumericValue(
    'package.baseGuestNumber',
    eventData
  )
  const basePrice = getOfferingNumericValue('package.basePrice', eventData)
  const pricePerPerson = getOfferingNumericValue('price', eventData)
  const groupSize = innerGroupSize || toInteger(groupSizeProp)
  const { intro } = eventData
  const firstImage = eventData?.images[idx + 1] || eventData?.images[0]
  const imagePath = getImageUrl(firstImage, '400x280')

  // 'as number' because typescript detects possibly undefined when using max function
  const guests = max([baseGuestNumber, groupSize]) as number
  const packagePrice = basePrice + guests * pricePerPerson
  const eventsCount = myEvents?.length || 0

  useEffect(() => {
    if (capacity === -1) {
      setCapacity(guests)
    }

    /**
     * since I use a Ref for the select, the component doesn't run useEffect again and the state doesn't change
     * so, I add a small delay for the select to be ready and do the checking
     */
    setTimeout(() => {
      const defaultEventId = sessionStorage.getItem('rsvp-default') || ''
      if (myEventsRefEventCard.current && !defaultEvent && defaultEventId) {
        myEventsRefEventCard.current.value = defaultEventId
        setDefaultEvent(defaultEventId)
      }
    }, 500)
  }, [capacity, defaultEvent, guests])

  const completed = () => {
    setEventdate('')
    setAdded(true)
  }

  const handleDateChange = (date: Moment) => {
    setEventdate(date.format('YYYY-MM-DD 00:00:00'))
  }

  return (
    <>
      <OfferingModal
        show={showModal}
        setShowModal={setShowModal}
        data={eventData}
        guests={guests}
        packagePrice={packagePrice}
        pricePerPerson={pricePerPerson}
      />
      <article className="eventcard">
        <h1 className="fz-18">{eventData.name}</h1>
        <div
          onClick={() => setShowModal(true)}
          className="eventcard__cover cover cursor"
          style={{ backgroundImage: `url(${imagePath})` }}
        >
          <span className="cursor" onClick={() => setShowModal(true)}>
            <MdMoreHoriz color="white" size={30} />
          </span>
        </div>
        <EventPrices
          className="mt-4"
          packagePrice={packagePrice}
          guests={guests}
          pricePerPerson={pricePerPerson}
        />
        <span className="eventcard__rules fz-13 tc-lgray">
          <span>Rate based on {guests} guests</span>
          <span>Capacity {eventData.quantity}</span>
        </span>

        <div
          className="eventcard__booking w-90 mx-auto pt-3 pb-4"
          style={{ borderBottom: '1px solid #c3c3c3' }}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <label
                    className="w-100 fz-13 m-0"
                    htmlFor="input-capacity-package"
                  >
                    Group size
                  </label>
                  <input
                    id="input-capacity-package"
                    className="input-capacity no-focus"
                    onChange={ev => {
                      const value = toInteger(ev.currentTarget.value)

                      if (value > eventData.quantity) {
                        setCapacity(eventData.quantity)
                        setInnerGroupSize(eventData.quantity)
                        setError(
                          `maximum number of guests exceeded (max: ${eventData.quantity})`
                        )
                      } else {
                        setCapacity(value)
                        setInnerGroupSize(value)
                        setError('')
                      }
                    }}
                    type="number"
                    value={capacity}
                    max={eventData.quantity}
                    min={eventData.package.minimumGuests || 1}
                  />
                </td>
                <td>
                  <label
                    className="w-100 fz-13 m-0"
                    htmlFor="single_picker_package"
                  >
                    Date
                  </label>
                  <SinglePicker emitDate={handleDateChange} />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {eventsCount > 0 && (
                    <>
                      <label className="m-0 fz-13" htmlFor="event">
                        Select your event
                      </label>
                      <select
                        id="select-myEvent-eventCard"
                        ref={myEventsRefEventCard}
                        className="custom-select select-myEvent-eventCard"
                      >
                        {myEvents?.map(myEvent => (
                          <option key={myEvent.id} value={myEvent.id}>
                            {myEvent.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {eventsCount <= 0 && (
                    <>
                      <label className="m-0 fz-13" htmlFor="event">
                        Event Name
                      </label>
                      <input
                        type="text"
                        className="form-control no-focus mb-2 event-name-package"
                        placeholder="Enter you event name"
                        value={eventName}
                        onChange={ev => setEventName(ev.currentTarget.value)}
                      />
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <CreateEventComponentCTA
            myEventsRef={myEventsRefEventCard}
            offering={eventData}
            input={{
              alias: null,
              capacity: capacity as number,
              duration: 1,
              guestPays: 0,
              date: eventDate,
            }}
            completed={completed}
            eventName={eventName}
            myEvents={myEvents}
            added={added}
            error={error}
            setError={setError}
          />
        </div>

        <div
          className="small-event-card"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: intro as string }}
        />
      </article>
    </>
  )
}
