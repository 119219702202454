import styled from 'styled-components'

import { salmon } from '../../styles/themeColors'

export const FooterHome = styled.footer`
  background-color: #f7f7f8;
  & .container {
    border-top: 1px solid #dbdbdb;
  }
  & a.map,
  & a.terms {
    color: #858484 !important;
    text-decoration: underline;
    &:hover {
      color: #858484 !important;
    }
  }
`

export const Container1st100Groups = styled.section`
  background-color: ${salmon};
  color: white;
  & input {
    outline: none;
  }
  & h2 {
    font-size: 2.187rem;
    font-weight: 700;
  }
  & p {
    font-size: 1rem;
    font-weight: 600;
  }
  @media (max-width: 991px) {
    & .photo {
      max-width: 690px;
    }
  }
  @media (max-width: 767px) {
    & .photo {
      max-width: 100%;
    }
  }
`
