import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { FaRegThumbsDown } from 'react-icons/fa'

import { SigninSignup } from '../../components'
import { salmon } from '../../styles/themeColors'
import { useMoveToTop, IframeYoutube, useCurrentUser } from '../../common'
import logo1 from '../../assets/images/about/1.webp'
import logo2 from '../../assets/images/about/2.webp'
import logo3 from '../../assets/images/about/3.webp'
import logo4 from '../../assets/images/about/4.webp'
import logo5 from '../../assets/images/about/5.webp'
import logo6 from '../../assets/images/about/6.webp'
import logo7 from '../../assets/images/about/7.webp'
import logo8 from '../../assets/images/about/8.webp'
import logo9 from '../../assets/images/about/9.webp'
import logo10 from '../../assets/images/about/10.webp'
import logo11 from '../../assets/images/about/11.webp'
import logo12 from '../../assets/images/about/12.webp'
import logo13 from '../../assets/images/about/13.png'
import logo14 from '../../assets/images/about/14.png'
import logo15 from '../../assets/images/about/15.png'
import logo16 from '../../assets/images/about/16.png'
import logo17 from '../../assets/images/about/17.png'
import logo18 from '../../assets/images/about/18.png'
import { myHub } from '../../common/constants'

import { TeamMember } from './components/AboutUtils'
import { Header, AboutUsInfoSection, FeaturedOn } from './style'

import '../../styles/about.sass'

export const About: React.FC = () => {
  useMoveToTop()
  const history = useHistory()
  const { isAuthenticated } = useCurrentUser()
  const [registerModal, openRegisterModal] = useState(false)

  const signupGA = (label: string): void => {
    if (isAuthenticated) {
      history.push(myHub)
    } else {
      openRegisterModal(true)
    }

    ReactGA.event({
      category: 'Homepage',
      label,
      action: 'User clicked the CTA button /about-us',
    })
  }

  return (
    <section className="public-container about-page">
      <Helmet>
        <title>Kiss & Tell | Destination Weddings Made Simple | About</title>
        <meta
          name="description"
          content="Kiss & Tell is a destination wedding planning platform where you can manage your guest lists, book hotel room blocks, and much more. We are a solution that you truly need!"
        />
      </Helmet>
      <Header>
        <h1 className="fwl">
          With so many digital wedding planning options, why are we still in
          pain?
        </h1>
      </Header>
      <section className="fz-16 mission-section py-5">
        <h2 className="text-center pt-1 fwl fz-35 px-2">
          Our promise to you:{' '}
          <span className="fwsb">A solution that you truly need!</span>
        </h2>

        <h3 className="text-center pb-4 fwl px-2">
          You’re over being force fed antiquated solutions and modern takes on
          minimal problems. Here’s what we won’t give you:
        </h3>
        <div className="fz-20 d-xs-block d-md-flex justify-content-center mt-5 text-center">
          {[
            'Material gifts when you really care about the experience with loved ones.',
            'Another wedding website builder although your guests find them full of unhelpful fluff.',
            "A paid vendor directory as we know you’ve moved on from early 2000's technology.",
          ].map(el => (
            <div
              style={{ width: '300px' }}
              className="mx-auto fz-20 mx-5 pb-3"
              key={el.length}
            >
              <FaRegThumbsDown size={150} color={salmon} />
              <p className="pt-4">{el}</p>
            </div>
          ))}
        </div>
        <div className="text-center py-4">
          <p className="fz-18">Ready to be the change in weddings?</p>
          <Button
            onClick={() => signupGA('About Us CTA')}
            className="fz-20 py-2 px-4 rounded-pill fwb"
          >
            Create your event
          </Button>
        </div>
        <SigninSignup
          open={registerModal}
          onClose={() => openRegisterModal(false)}
          type="register"
          id="CTA-about-us"
        />
      </section>
      <section className="py-5">
        <h2 className="fwl fz-45 pb-2 pb-md-5 m-0 text-center">
          <span className="fwsb fz-13 d-block mb-2 text-primary after-line">
            OUR SOLUTION
          </span>
          Streamlined Social Event Planning
        </h2>

        <AboutUsInfoSection>
          <img
            className="img-fluid"
            src="/img/about/wedding_hosts.png"
            alt="Kiss the Headache of Group Gatherings Goodbye!"
          />
          <div className="info-section-content">
            <span className="sub-title">HOSTS</span>
            <h3>
              Kiss the Headache of
              <br />
              Group Gatherings Goodbye!
            </h3>
            <p>Easily build, compare and book events and group stays</p>
            <p>
              Manage your group, their travel and communications all in one spot
            </p>
            <p>
              Experience more quality time with automated group deal flow,
              booking and logistics{' '}
            </p>
          </div>
        </AboutUsInfoSection>

        <AboutUsInfoSection>
          <div className="info-section-content">
            <span className="sub-title">GUESTS</span>
            <h3>Get there Without the Headache</h3>
            <p>
              Manage your attendance and travel to multiple events in one place
            </p>
            <p>
              Have a say in the plans with group communication and integrated
              booking
            </p>
            <p>Stay longer, enjoy more and don’t miss out!</p>
          </div>
          <img
            className="img-fluid"
            src="/img/about/wedding_guests.png"
            alt="Get there Without the Headache"
          />
        </AboutUsInfoSection>

        <AboutUsInfoSection>
          <img
            className="img-fluid"
            src="/img/about/wedding_cake_vendor.png"
            alt="Rethink Weddings for the Better"
          />
          <div className="info-section-content">
            <span className="sub-title">VENDORS</span>
            <h3>Rethink Weddings for the Better</h3>
            <p>New revenue channel with no upfront fees</p>
            <p>
              Match customers more accurately with more complete data display{' '}
            </p>
            <p>
              Save time with automated package building, customer deal flow,
              communications and billing
            </p>
          </div>
        </AboutUsInfoSection>
      </section>
      <section className="bg-lgray pb-5">
        <h2 className="fz-35 fwl py-5 m-0 text-center after-line">Our Team</h2>
        <div className="d-flex justify-content-center flex-wrap">
          <TeamMember
            link="https://www.linkedin.com/in/amy-jarczynski-235a164/"
            photo="team_amy-jarczynski.png"
            title="Amy Jarczynski"
            subtitle="Founder, CEO"
          />
          <TeamMember
            link="https://www.linkedin.com/in/bencheatham/"
            photo="team_ben-cheatham.png"
            title="Ben Cheatham"
            subtitle="Co-Founder, CTO"
          />
          <TeamMember
            link="https://www.linkedin.com/in/youroff/"
            photo="team_ivan_yurov.png"
            title="Ivan Yurov"
            subtitle="Principal Engineer"
          />
        </div>
        <div className="d-flex justify-content-center flex-wrap pt-4 pb-3">
          <TeamMember
            link="https://www.linkedin.com/in/dainareed/"
            photo="team_daina-reed.png"
            title="Daina Reed"
            subtitle="Design Director"
          />
          <TeamMember
            link="https://www.linkedin.com/in/davina-coard-martoral-8bba1949/"
            photo="team_davina_coard_martoral.png"
            title="Davina Coard-Martoral"
            subtitle="PR Director"
          />
          <TeamMember
            link="https://www.linkedin.com/in/juan-sánchez-96b90a142/"
            photo="team_jaun_sanchez.png"
            title="Juan Sanchez"
            subtitle="Senior Frontend Engineer"
          />
          <TeamMember
            link="https://www.linkedin.com/in/manoj-samanta-25a7111/"
            photo="team_manoj_samanta.png"
            title="Manoj Samanta"
            subtitle="Senior Backend Engineer"
          />
        </div>
      </section>
      <section className="bg-lgray pb-5">
        <h2 className="fz-35 fwl pt-5 text-center after-line">Featured On</h2>
        <FeaturedOn className="container py-5">
          <img src={logo1} alt="The Knot" />
          <img src={logo2} alt="weddings" />
          <img src={logo3} alt="Great day washington" />
          <img src={logo4} alt="jetset" />
          <img src={logo5} alt="google for startups" />
          <img src={logo6} alt="NBC NEWS" />

          <img src={logo7} alt="ELLE" />
          <img src={logo8} alt="The daily buzz" />
          <img src={logo9} alt="Harber Turk" />
          <img src={logo10} alt="Great day houston" />
          <img src={logo11} alt="OK!" />
          <img src={logo12} alt="The National" />

          <img src={logo13} alt="Austininno" />
          <img src={logo14} alt="Axios Austin" />
          <img src={logo15} alt="Forbes" />
          <img src={logo16} alt="Kvue abc" />
          <img src={logo17} alt="manoto" />
          <img src={logo18} alt="siliconhills" />
        </FeaturedOn>
      </section>
      <section className="bg-white py-5">
        <Row className="py-5">
          <Col xs={12} md={6} className="text-center">
            <div className="d-flex flex-column justify-content-center h-100 align-items-center">
              <h2 className="fz-50 font-lf text-primary">Reschedule with us</h2>
              <p className="tc-lgray fwsb fz-18" style={{ maxWidth: '437px' }}>
                We don’t know what’s worse, the heartache of cancelling your
                dream wedding or the headache of planning your wedding twice,
                but we can make sure that it never happens again!
              </p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="px-5">
              <IframeYoutube videoId="jAJuzPinbPo" />
            </div>
          </Col>
        </Row>
      </section>
    </section>
  )
}
