import React from 'react'
import { useHistory } from 'react-router-dom'

import { MyEvent, Event } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setCurrentEventId } from '../../features/siteSlice'
import { saveSelectedEvent, useGuestAcceptInvitacion } from '../../common'

import { EventButtonsContainer } from './style'

const EventSelector: React.FC<{
  myEvents: MyEvent[]
  events: Event[]
}> = ({ myEvents, events }) => {
  const history = useHistory()
  const { updateGuestState } = useGuestAcceptInvitacion()
  const eventList: (MyEvent | Event)[] = [...myEvents, ...events]
  const dispatch = useAppDispatch()
  const { currentEventId } = useAppSelector(state => state.site)

  const setEvent = (eventId: string) => {
    history.push('/hub')
    saveSelectedEvent(eventId) // save the selected event in local storage
    updateGuestState(eventId)
    dispatch(setCurrentEventId(eventId)) // since we have redux we can save the selected event in the store to not pass it trough many levels
  }

  return (
    <EventButtonsContainer data-cy="event-selector">
      {eventList.map(event => (
        <button
          type="button"
          key={event.id}
          className={`btn-event-selector ${
            event.id === currentEventId ? 'btn-salmon' : ''
          }`}
          onClick={() => setEvent(event.id)}
        >
          {event.name}
        </button>
      ))}
    </EventButtonsContainer>
  )
}

export default EventSelector
