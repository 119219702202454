import React, { useState } from 'react'
import Swal from 'sweetalert2'
import ReactGA from 'react-ga4'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useMutation } from '@apollo/react-hooks'
import { GiGlassCelebration } from 'react-icons/gi'
import { MdCancel, MdPeople, MdMap } from 'react-icons/md'

import { DataCaptureFlow } from '../../../types'
import { gray } from '../../../styles/themeColors'
import { SimpleKnTField } from '../../../components'
import { CREATE_MARKETING_FORM } from '../../../graphql'
import { SignInUpContainer } from '../../../components/SigninSignup/style'
import {
  errorAlert,
  getCalendlyData,
  getLandingPageResponseId,
  loaderAlert,
  simpleAlert,
} from '../../../common'

export const CalendlyModal: React.FC<{
  open: boolean
  onClose: () => void
}> = ({ open, onClose }) => {
  const [step, setStep] = useState<DataCaptureFlow>('additional-info')
  const [FreeConsultation] = useMutation(CREATE_MARKETING_FORM)

  const sendFreeConsultation = (ev: React.FormEvent) => {
    ev.preventDefault()

    const form = ev.currentTarget as HTMLFormElement
    const weddingDate = form?.wedding_date.value
    const idealDestination = form?.ideal_destination.value
    const groupSize = form?.group_size.value

    loaderAlert({ html: 'Sending your information...' })
    ReactGA.event({
      category: 'FREE consultation',
      label: 'Sending ADDITIONAL DETAILS',
      action: 'Landing page - Sending additional details',
    })

    const variablesForConsultation = {
      input: {
        form_name: 'Free Consultation',
        form_data: JSON.stringify({
          weddingDate,
          idealDestination,
          groupSize,
        }),
        assoc_type: 'users',
        assoc_id: getLandingPageResponseId(),
      },
    }

    FreeConsultation({ variables: variablesForConsultation })
      .then(({ data }) => {
        const err = data.createMarketingForm.errors || []
        if (err.length > 0) {
          errorAlert(
            err,
            'Your registration could not be completed, please try again later.'
          )
        } else {
          Swal.close()
          setStep('success')
        }
      })
      .catch(() => {
        simpleAlert({
          html: `Your registration could not be completed, please try again later.`,
          icon: 'warning',
        })
      })
  }

  const calendly = getCalendlyData()
  return (
    <Modal centered show={open} onHide={onClose} className="modal-rounded-0">
      <SignInUpContainer className="px-3 px-lg-5 py-4">
        <MdCancel size={20} className="closeModal cursor" onClick={onClose} />

        {step === 'additional-info' && (
          <>
            <h5 className="text-primary text-center w-100 fwsb fz-20">
              You’re all set! Your concierge will be in touch shortly!
            </h5>
            <p className="fz-14 w-100 text-center mb-4">
              In the meantime, feel free to provide additional details below
            </p>
            <Form
              data-cy="signup-form-consultation-extra-details"
              onSubmit={sendFreeConsultation}
            >
              <div className="destination pt-1">
                <SimpleKnTField
                  label="Target wedding date"
                  name="wedding_date"
                  placeholder="Enter the wedding date"
                  type="date"
                  icon={<GiGlassCelebration size={24} color={gray} />}
                />
                <SimpleKnTField
                  label="Ideal destination"
                  name="ideal_destination"
                  placeholder="Enter your ideal destination"
                  icon={<MdMap size={24} color={gray} />}
                />
                <SimpleKnTField
                  label="Group size"
                  name="group_size"
                  placeholder="Enter the group size. ex: 50"
                  type="number"
                  icon={<MdPeople size={24} color={gray} />}
                />
              </div>
              <Button className="w-100 my-4" type="submit">
                Submit
              </Button>
            </Form>
            <Button
              className="w-100 mb-2 mt-3"
              onClick={() => {
                setStep('success')
                ReactGA.event({
                  category: 'FREE consultation',
                  label: 'Skip Additional Details',
                  action: 'User clicked "Skip" button',
                })
              }}
              variant="outline-primary"
            >
              Skip
            </Button>
          </>
        )}

        {step === 'success' && (
          <div className="py-3">
            <h4 className="text-primary text-center w-100 fwb fz-22 pb-3">
              Awesome!
              <br />
              Can’t wait to start planning?
            </h4>
            <a
              href={`https://calendly.com/kissandtellamy/30min?name=${calendly?.firstName}%20${calendly?.lastName}&email=${calendly?.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white d-block w-fit-content mx-auto"
              onClick={() => {
                ReactGA.event({
                  category: 'FREE consultation',
                  label: 'Schedule Call',
                  action: 'User clicked "Schedule Your Call" button',
                })
              }}
            >
              <Button className="fz-18 fwsb px-5 py-2 rounded-pill">
                Schedule Your Call
              </Button>
            </a>
            <Button
              size="sm"
              variant="outline-secondary"
              className="mt-3 mx-auto d-block px-4"
              onClick={() => {
                setStep('additional-info')
                onClose()
              }}
            >
              Close
            </Button>
          </div>
        )}
      </SignInUpContainer>
    </Modal>
  )
}
