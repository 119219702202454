import React, { useState, useCallback } from 'react'
import Button from 'react-bootstrap/Button'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { CgSmileNeutral, CgSmile, CgSmileSad } from 'react-icons/cg'

import eventIcon from '../../../../assets/images/icons/eventicon.svg'
import { useAppDispatch } from '../../../../hooks'
import { Sublists } from '../../../../components/Tiers'
import { RSVPcta } from '../../../SocialPanel/components'
import { CustomTabs } from '../../../../styles/commonStyles'
import { GET_AVAILABLE_PACKAGES } from '../../../../graphql'
import { faceIconColor } from '../../../../styles/themeColors'
import { setOnBehalfOfGuest } from '../../../../features/bookingSlice'
import { getImageUrl, getCheckIn } from '../../../../common'
import { FormatNumber, Loader } from '../../../../components'
import {
  Booking,
  BookingStatus,
  EventComponent,
  Guest,
} from '../../../../types'

import { BookingsContainer, RsvpManagerContainer } from './style'
import { YourPartyTable } from './YourPartyTable'
import { sortPackages } from './guestListFunctions'

const ManageRSVP: React.FC<{
  eventId: string
  currentGuest?: Guest
  bookings: Booking[]
  filterPackages: string[]
}> = ({ eventId, bookings = [], currentGuest, filterPackages }) => {
  const dispatch = useAppDispatch()
  const { data, loading } = useQuery(GET_AVAILABLE_PACKAGES, {
    variables: { eventId, guestId: currentGuest?.id },
    fetchPolicy: 'network-only',
  })

  // Memoize filter function to optimize performance
  // Recreates only when 'bookings' changes to avoid re-rendering the component
  const getBookingsByEC = useCallback(
    (ecId: string) => bookings.filter(b => b.eventComponent.id === ecId),
    [bookings]
  )

  if (loading) return <Loader text="Loading options..." />
  const availablePackages: EventComponent[] = data?.availablePackages ?? []
  if (availablePackages.length <= 0) {
    return (
      <div className="text-center py-3 py-lg-5">
        <h4>No available packages</h4>
      </div>
    )
  }

  const className = availablePackages.length === 1 ? 'single' : ''
  const orderedPackages = sortPackages(availablePackages)

  const setGuestDataForBooking = () => {
    if (!currentGuest) return
    dispatch(setOnBehalfOfGuest(currentGuest))
  }

  const handleClickMoreDetails = (container: string) => {
    // this is just to add or remove the class collapsed/expanded so its not necessary to use the state
    // this way we avoid re-rendering the component
    const containerEl = document.querySelector(`.details-${container}`)
    const ctaText = document.querySelector(`#cta-text-${container}`)
    if (!containerEl || !ctaText) return

    const isCollapsed = containerEl.classList.toggle('collapsed') // classList.toggle() returns a boolean value if the class was added and false if removed
    ctaText.textContent = isCollapsed ? 'View' : 'Hide'
  }

  const getFaceIcons = (bookingList: Booking[]): React.ReactElement[] => {
    if (bookingList.length <= 0) {
      return [
        <CgSmileNeutral
          size={35}
          color={faceIconColor.neutral}
          title="No Bookings"
        />,
      ]
    }
    const faceIcons: React.ReactElement[] = []
    bookingList.forEach(booking => {
      const { status } = booking
      if (status === BookingStatus.Booked) {
        faceIcons.push(
          <CgSmile size={35} color={faceIconColor.happy} title="Booked" />
        )
      } else {
        faceIcons.push(
          <CgSmileSad size={35} color={faceIconColor.sad} title="Declined" />
        )
      }
    })
    return faceIcons
  }

  return (
    <BookingsContainer className={className}>
      {orderedPackages.map(ec => {
        const bookingsByEC = getBookingsByEC(ec.id)
        const showFaceIcons =
          filterPackages.length === 0 || filterPackages.includes(ec.id)
        return (
          <>
            <div key={`row-${ec.id}`} className={`b-row ${className}`}>
              <div className="offering-data">
                <p className="fz-18 fwsb px-3 px-md-0 mb-1">
                  {ec?.alias ?? ec.offering.name}
                  <span className="d-block fz-14 text-primary">
                    {ec.offering.provider.name}
                  </span>
                </p>
                <p className="d-flex m-0 fwsb fz-15">
                  {getCheckIn(ec.date, 'dddd, MMMM DD, YYYY')}
                </p>
                <div className="fz-16 fwsb px-3 px-md-0 py-2">
                  Price: <FormatNumber n={ec.offering.price} />
                </div>

                <div>
                  <RSVPcta
                    className="px-3 py-1"
                    eventComponent={ec}
                    RsvpCustomText={
                      ec.customTexts?.timeline ?? 'RSVP for guest'
                    }
                    customCallback={setGuestDataForBooking}
                  />

                  {bookingsByEC.length > 0 && (
                    <Button
                      variant="outline-primary"
                      className="ml-2"
                      onClick={() => handleClickMoreDetails(ec.id)}
                    >
                      <MdAdd size={18} className="svg-top1 mr-1" />
                      <span id={`cta-text-${ec.id}`}>View</span> details
                    </Button>
                  )}
                </div>

                <Sublists
                  tiers={ec.tiers}
                  className="mt-3"
                  badgeClassName="fz-11"
                />
              </div>

              {showFaceIcons && (
                <div className="mx-auto px-3 text-center">
                  <p className="fwsb mb-1">Your party</p>
                  {getFaceIcons(bookingsByEC)}
                </div>
              )}

              <img
                src={getImageUrl(ec.offering.mainImage, '400x250')}
                alt={ec.offering.name}
              />
            </div>
            <YourPartyTable bookings={bookingsByEC} />
          </>
        )
      })}
    </BookingsContainer>
  )
}

export const PackageBookingsModal: React.FC<{
  bookings: Booking[]
  currentGuest?: Guest
  filterPackages: string[]
}> = ({ bookings = [], currentGuest, filterPackages }) => {
  const { eventId } = useParams<{ eventId: string }>()
  const [activeTab, setActiveTab] = useState(0)

  // Define the structure for tabs
  // This makes it easy to add or modify tabs in the future
  // we are going to keep it this way for now in case we need to add more tabs we can just add them here without changing the logic
  const tabs = [
    { index: 0, icon: eventIcon, label: 'RSVP List' }, // we only have 1 tab for the moment
  ]

  return (
    <RsvpManagerContainer>
      <CustomTabs className="mb-3">
        {tabs.map(tab => (
          <button
            key={tab.index}
            type="button"
            onClick={() => activeTab !== tab.index && setActiveTab(tab.index)}
            className={activeTab === tab.index ? 'active' : ''}
          >
            <img src={tab.icon} alt={tab.label} />
            {tab.label}
          </button>
        ))}
      </CustomTabs>
      <ManageRSVP
        currentGuest={currentGuest}
        eventId={eventId}
        bookings={bookings}
        filterPackages={filterPackages}
      />
    </RsvpManagerContainer>
  )
}
