import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import {
  saveSelectedEvent,
  saveSocialMediaSource,
  useReadToken,
  useTrackView,
} from '../common'
import { myHub } from '../common/constants'

export const Redirect: React.FC = () => {
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const { payload } = useReadToken(token)
  const { track } = useTrackView()

  useEffect(() => {
    if (!token) {
      // in case of no token, redirect to home page
      history.push('/')
      return
    }
    if (!payload) {
      // after reading token, if there is no payload, redirect to home page
      history.push('/')
      return
    }

    const destination = payload?.destination as string
    if (destination === 'feed') {
      // pre-select event and redirect to feed
      const eventId = payload?.event_id as string
      saveSelectedEvent(eventId)

      track({
        eventId,
        guestId: payload?.guest_id as string,
        source: payload?.source as string,
        destination,
      })

      history.push(myHub)
    }

    // generic use case - for now for social media sources
    const source = payload?.source as string
    saveSocialMediaSource(source)
    track({
      eventId: payload?.event_id as string,
      guestId: payload?.guest_id as string,
      source,
      destination,
    })

    history.push(destination)

    // if we add track to the dependency array, it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, payload])

  return <div className="text-center py-5">redirecting...</div>
}
