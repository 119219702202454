import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MdCancel } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { ISignInUpPreloadedData, authModalType } from '../../types'
import { myHub } from '../../common/constants'

import { SignInUpContainer } from './style'
import { Signin } from './Signin'
import { Signup } from './Signup'

export interface ISigninSignup {
  open: boolean
  onClose: () => void
  type?: authModalType
  id?: string
  preloadedData?: ISignInUpPreloadedData
  topText?: string
  redirectToHome?: boolean
}

const topTextComp = (text: string) => (
  <p className="w-75 mx-auto text-center fz-13 tc-lgray">{text}</p>
)

export const SigninSignup: React.FC<ISigninSignup> = ({
  open,
  onClose,
  type = 'login',
  id,
  preloadedData,
  topText,
  redirectToHome,
}) => {
  const history = useHistory()

  const close = () => {
    onClose()
    if (redirectToHome) {
      history.push(myHub)
    }
  }
  return (
    <Modal show={open} onHide={onClose} className="modal-rounded-0">
      <SignInUpContainer>
        <MdCancel size={20} className="closeModal cursor" onClick={onClose} />
        <Tabs defaultActiveKey={type} id={id || 'SigninSignupTabs'}>
          <Tab eventKey="login" title="Login">
            {topText && topTextComp(topText)}
            <Signin onClose={close} preloadedData={preloadedData} />
            <Link
              to="/reset-password"
              className="w-100 d-block text-center fz-14 my-4"
              onClick={onClose}
            >
              Forgot password?
            </Link>
          </Tab>
          <Tab eventKey="register" title="Register">
            {topText && topTextComp(topText)}
            <Signup preloadedData={preloadedData} onClose={close} />
          </Tab>
        </Tabs>
      </SignInUpContainer>
    </Modal>
  )
}
