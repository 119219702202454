import React from 'react'
import { Helmet } from 'react-helmet'

import collage from '../../assets/images/Landingpages/equallywed_collage.png'
import { useMoveToTop } from '../../common'
import { Wave } from '../../components'
import { pink, salmon } from '../../styles/themeColors'

import {
  Header,
  ComparisonSection,
  FormSection,
  CreditsSection,
  VideoSection,
} from './components'

export const EquallyWed: React.FC = () => {
  useMoveToTop()

  return (
    <section className="public-container public-page">
      <Helmet>
        <title>Kiss & Tell | Equally Wed</title>
        <meta
          name="description"
          content="Discover boundless love at Kiss & Tell | Equally Wed! Explore inclusive weddings for all. Join our journey to marital equality"
        />
      </Helmet>
      <Header />
      <Wave
        color={salmon}
        style={{
          zIndex: 3,
          rotate: '180deg',
          position: 'relative',
          top: '-15px',
        }}
      />
      <ComparisonSection />
      <Wave
        color={pink}
        style={{
          position: 'relative',
          bottom: '-1px',
          zIndex: 3,
        }}
      />
      <FormSection formName="equallywed" referredBy="Equally Wed" />
      <Wave
        color={pink}
        style={{
          transform: 'rotate(180deg)',
          position: 'relative',
          top: '-1px',
        }}
      />
      <CreditsSection
        title="Gain access to Equally Wed Credits"
        collage={collage}
      />

      <VideoSection />
    </section>
  )
}
