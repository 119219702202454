import gql from 'graphql-tag'

export const CREATE_TOKEN = gql`
  mutation createToken($email: String!, $password: String!, $role: Role!) {
    createToken(role: $role, email: $email, password: $password) {
      result {
        accessToken
        avatarUrl
        firstName
        lastName
        refreshToken
        role
        userId
        email
      }
      errors {
        key
        message
      }
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      result {
        accessToken
        refreshToken
      }
      errors {
        key
        message
      }
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: PasswordInput!) {
    resetPassword(input: $input) {
      result {
        accessToken
        accessTokenExpiresAt
        avatarUrl
        firstName
        lastName
        refreshToken
        refreshTokenExpiresAt
      }
      errors {
        key
        message
      }
    }
  }
`

export const VERIFY_ACCOUNT = gql`
  mutation verifyAccount($secret: String!, $event: String) {
    verifyAccount(secret: $secret, event: $event) {
      errors {
        key
        message
      }
      result {
        isVerified
        token {
          accessToken
          avatarUrl
          firstName
          lastName
          refreshToken
          role
          userId
        }
      }
    }
  }
`

export const RESET_PASSWORD_LINK = gql`
  mutation passwordResetLink($email: String) {
    passwordResetLink(email: $email) {
      errors {
        key
        message
      }
    }
  }
`

export const SET_PASSWORD_LANDING_USER = gql`
  mutation setPasswordLandingUser(
    $token: String!
    $password: String!
    $event: ID
  ) {
    setPasswordLandingUser(token: $token, password: $password, event: $event) {
      result {
        isVerified
        token {
          accessToken
          avatarUrl
          firstName
          lastName
          refreshToken
          role
          userId
        }
      }
      errors {
        key
        message
      }
    }
  }
`
