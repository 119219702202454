import React from 'react'
import Swal from 'sweetalert2'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/react-hooks'
import { MdAccountCircle, MdMailOutline, MdPhone } from 'react-icons/md'
import { HiOutlineCheck } from 'react-icons/hi'

import {
  useMoveToTop,
  IframeYoutube,
  simpleAlert,
  loaderAlert,
} from '../../common'
import { useForm } from '../../common/alterForms'
import { KnTField } from '../../components'
import { CREATE_MARKETING_FORM } from '../../graphql'
import { Error } from '../../types'
// reusing partnership page style
import { YoutubeList } from '../Partnership/YoutubeList'
import { Header, PartnershipYoutube } from '../Partnership/style'
import { salmon } from '../../styles/themeColors'
import Collage from '../../assets/images/referrals/collage.jpg'

import { ReferralForm } from './style'
import '../../styles/partnership.sass'

interface IReferralsForm {
  [s: string]: string
}

export const Referrals: React.FC = () => {
  useMoveToTop()
  const initModel: IReferralsForm = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    referencedFirstName: '',
    referencedLastName: '',
    referencedEmail: '',
    referencedPhone: '',
  }
  const [SaveReferralData, { loading }] = useMutation(CREATE_MARKETING_FORM)

  const { model, errors, handleFieldChange, pushError, reset } =
    useForm(initModel)

  const sendForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (model.email === model.referencedEmail) {
      simpleAlert({ html: 'Emails cannot be the same', icon: 'info' })
    } else if (!loading) {
      loaderAlert({ html: 'processing data, please wait a moment' })
      const variables = {
        input: {
          form_name: 'referral',
          form_data: JSON.stringify(model),
        },
      }
      SaveReferralData({ variables }).then(({ data }) => {
        Swal.close()
        if (data.createMarketingForm.errors.length === 0) {
          simpleAlert({ html: 'Data sent successfully', icon: 'success' })
          reset(initModel)
        } else {
          data.createMarketingForm.errors.forEach(({ key, message }: Error) => {
            pushError(message, key)
          })
        }
      })
    }
  }

  return (
    <section className="public-container public-page">
      <Helmet>
        <title>Kiss & Tell | Refer a wedding and save on your travel!</title>
        <meta
          name="description"
          content="Refer a wedding, gathering, reunion or retreat and save $300 on your travel!"
        />
      </Helmet>
      <Header className="ReferralStyle">
        <h1 className="title">Get there for $300 less!</h1>
        <h3 className="subtitle">
          Know someone planning a wedding, gathering, reunion or retreat? <br />
          Refer them to us and you’ll save $300 getting there!
        </h3>
      </Header>

      <ReferralForm onSubmit={sendForm}>
        <h1 className="text-center text-primary fwb fz-24 mt-5 mb-3">
          Refer a gathering
        </h1>
        <div className="form w-90 mx-auto d-block">
          <Row>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Your first name"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="firstName"
                placeholder="Enter your First Name"
                icon={<MdAccountCircle size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Your last name"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="lastName"
                placeholder="Enter your Last Name"
                icon={<MdAccountCircle size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Your phone"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="phone"
                placeholder="Enter your phone"
                icon={<MdPhone size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Your email"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="email"
                type="email"
                placeholder="Enter your email"
                icon={<MdMailOutline size={24} color={salmon} />}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Host’s first name"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="referencedFirstName"
                placeholder="Enter host’s first name"
                icon={<MdAccountCircle size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Host’s last name"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="referencedLastName"
                placeholder="Enter host’s last name"
                icon={<MdAccountCircle size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Host’s phone"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="referencedPhone"
                placeholder="Enter host’s phone"
                icon={<MdPhone size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <KnTField
                label="Host’s email"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="referencedEmail"
                type="email"
                placeholder="Enter host’s email"
                icon={<MdMailOutline size={24} color={salmon} />}
              />
            </Col>
          </Row>
        </div>
        <Button type="submit" disabled={loading} className="send py-2 my-5">
          Send
        </Button>

        <Row className="fwsb pt-4 fz-18 tc-lgray px-4">
          <Col sm={12} md={6}>
            <h3 className="text-center font-lf fz-50 text-primary">
              They get:{' '}
            </h3>
            <p>
              <HiOutlineCheck color={salmon} size={48} />
              <span>Streamlined planning, communications & logistics</span>
            </p>
            <p>
              <HiOutlineCheck color={salmon} size={48} />
              <span>Free engagement trip with wedding booking</span>
            </p>
            <p>
              <HiOutlineCheck color={salmon} size={48} />
              <span>Complimentary concierge</span>
            </p>
          </Col>
          <Col sm={12} md={6}>
            <h3 className="text-center font-lf fz-50 text-primary">
              You get:{' '}
            </h3>
            <p>
              <HiOutlineCheck color={salmon} size={48} />
              <span>Attendance and travel management one stop shop</span>
            </p>
            <p>
              <HiOutlineCheck color={salmon} size={48} />
              <span>$300 travel credit when they book on Kiss & Tell</span>
            </p>
            <p>
              <HiOutlineCheck color={salmon} size={48} />
              <span>Easy open communication with all guests</span>
            </p>
          </Col>
        </Row>
      </ReferralForm>

      <section className="text-center py-5">
        <h2 className="text-primary font-lf fz-50 after-line">
          Be the change needed in gatherings!
        </h2>
        <p className="fz-35 fz-sm-25 fwl max-1100">
          Finally a better way to get there together!
        </p>
      </section>
      <img
        src={Collage}
        alt="Vendor Collage"
        style={{
          display: 'block',
          width: '96%',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      />

      <PartnershipYoutube className="pt-0 pt-lg-5">
        <Row>
          <Col xs={12} md={6} className="PartnershipYoutube">
            <div className="PartnershipYoutube__info">
              <h2>We’re building a travel community with perks</h2>
              <p>
                Join us for your next journey. Invite your group, communicate
                and select an expert curated location together. We’ll get
                everyone there without the headache!
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <IframeYoutube videoId="jAJuzPinbPo" />
          </Col>
        </Row>
      </PartnershipYoutube>
      <h2 className="text-primary fwl fz-35 text-center px-5 mt-5">
        Our expertise spans the globe and lands right in your home
      </h2>
      <YoutubeList />
    </section>
  )
}
